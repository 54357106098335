import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Checkbox, Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import UsersTable from "../../components/usersTable";
import SelectUserModal from "../../components/modals/SelectUserModal";
import {RouteComponentProps, withRouter} from "react-router";
import {IAppState} from "../../models/app/AppState";
import IUser from "../../models/user/IUser";
import IFlashCard from "../../models/flashcard/IFlashCard";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import IGroup from "../../models/group/IGroup";
import {AnyAction} from "redux";
import {
    addGroupAction, deleteGroupAction,
    fetchGroupsAction,
    fetchGroupUsersAction,
    updateGroupAction
} from "../../models/group/GroupActions";
import {ISession} from "../../models/session/ISession";
import FlashCardsTable from "../../components/flashCardsTable";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import {addSessionAction} from "../../models/session/SessionActions";
import ISentence from "../../models/sentences/ISentence";
import moment from "moment";
import 'moment/locale/en-il';
import ISessionDay from "../../models/ISessionDay";
import {levels} from "../../utils/Levels";
function mapStateToProps(state:IAppState) {
    return {

    };
}

const mapDispatchToProps = {
    addGroupAction:addGroupAction,
    updateGroupAction:updateGroupAction,

};
interface IProp {
    addGroupAction:(group:IGroup,days:ISessionDay[],callBack:Function)=>AnyAction
    updateGroupAction:(group:IGroup,days:ISessionDay[],callBack:Function)=>AnyAction
    deleteGroupAction:(group:IGroup)=>AnyAction
}
interface IState {
    group:IGroup,
    error?:string;
    success?:string;
    days:ISessionDay[],
    editMode:boolean;
}
class AddGroup extends Component<IState&IProp&RouteComponentProps> {
    constructor(props:any) {
        super(props);

    }
    state:IState= {

        group: {name: '', level: '0',status:true,GroupSessionsDays:[],maxStudents:0,emptySeats:0},
        days: [
            {day:1,selected: false, startHour: '', endHour: ''},
            {day:2,selected: false, startHour: '', endHour: ''},
            {day:3,selected: false, startHour: '', endHour: ''},
            {day:4,selected: false, startHour: '', endHour: ''},
            {day:5,selected: false, startHour: '', endHour: ''},
            {day:6,selected: false, startHour: '', endHour: ''}
        ],
        editMode:false
    }
    componentWillMount() {
        this.checkAdmin();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("group")){
            let group=localState.group;
            let groupDays:ISessionDay[]=group.GroupSessionsDays;
            let days=this.state.days;
            for(let i=0;i<days.length;i++){
                for(let j=0;j<groupDays.length;j++){
                    if(days[i].day==groupDays[j].day){
                        days[i].selected=true;
                        days[i].startHour=groupDays[j].startHour;
                        days[i].endHour=groupDays[j].endHour;
                    }
                }
            }
            this.setState({group:group,days:days,editMode:true})

            return;
        }
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }


    setTitle(val:string){
        let s=this.state.group;
        s.name=val;
        this.setState({session:s})
    }
    setLevel(level:string){
        let s=this.state.group;
        s.level=level;
        this.setState({session:s})
    }
    setMaxStudent(max:number){
        let s=this.state.group;
        s.maxStudents=max;
        this.setState({session:s})
    }
    setDay(value:boolean,index:number){
        let days=this.state.days;
        days[index].selected=!days[index].selected;
        this.setState({days:days})
    }
    setStartHour(value:string,index:number){
        let days=this.state.days;
        days[index].startHour=value;
        this.setState({days:days})
    }
    setEndHour(value:string,index:number){
        let days=this.state.days;
        days[index].endHour=value;
        this.setState({days:days})
    }
    addGroup(){
        let days=this.state.days.filter(d=>d.selected==true);
        if(this.state.editMode){
            this.props.updateGroupAction(this.state.group,days,this.handleAddGroup.bind(this));
            return;
        }
        this.props.addGroupAction(this.state.group,days,this.handleAddGroup.bind(this));
    }
    handleAddGroup(success:boolean,msg:string){
        if(success){
            this.setState({success:msg})
        }else{
            this.setState({error:msg})
        }
    }
    render() {

        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'שם'} value={this.state.group.name} onChange={(event:any)=>this.setTitle(event.target.value)}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>

                                    <AppSelect options={levels} value={this.state.group.level} onChange={(event:any)=>this.setLevel(event.target.value)}/>

                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'מספר תלמידים'} value={this.state.group.maxStudents} onChange={(event:any)=>this.setMaxStudent(event.target.value)}/>
                                </Col>
                            </Row>
                            <h4 style={{textAlign:'right',marginTop:20}}>ימי למידה</h4>
                            <Row>
                                <Col  >
                                    <Checkbox style={{fontSize:18}} checked={this.state.days[0].selected}  onChange={e => this.setDay(e.target.value,0)} >א</Checkbox>
                                </Col>
                                <Col hidden={this.state.days[0].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'משעה'} value={this.state.days[0].startHour} onChange={(e:any)=>this.setStartHour(e.target.value,0)}/>
                                </Col>
                                <Col hidden={this.state.days[0].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'עד שעה'} value={this.state.days[0].endHour}  onChange={(e:any)=>this.setEndHour(e.target.value,0)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Checkbox style={{fontSize:18}} checked={this.state.days[1].selected}  onChange={e => this.setDay(e.target.value,1)} >ב</Checkbox>
                                </Col>

                                <Col hidden={this.state.days[1].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'משעה'} value={this.state.days[1].startHour} onChange={(e:any)=>this.setStartHour(e.target.value,1)}/>
                                </Col>
                                <Col hidden={this.state.days[1].selected==false} style={{marginRight:10}} >
                                    <AppInput type={'time'} placeholder={'עד שעה'} value={this.state.days[1].endHour} onChange={(e:any)=>this.setEndHour(e.target.value,1)}/>
                                </Col>


                            </Row>
                            <Row>
                                <Col >
                                    <Checkbox style={{fontSize:18}} checked={this.state.days[2].selected} onChange={e => this.setDay(e.target.value,2)}>ג</Checkbox>
                                </Col>

                                <Col hidden={this.state.days[2].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'משעה'}  value={this.state.days[2].startHour} onChange={(e:any)=>this.setStartHour(e.target.value,2)}/>
                                </Col>
                                <Col hidden={this.state.days[2].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'עד שעה'} value={this.state.days[2].endHour} onChange={(e:any)=>this.setEndHour(e.target.value,2)}/>
                                </Col>


                            </Row>
                            <Row>
                                <Col >
                                    <Checkbox style={{fontSize:18}} checked={this.state.days[3].selected} onChange={e => this.setDay(e.target.value,3)}>ד</Checkbox>
                                </Col>

                                <Col hidden={this.state.days[3].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'משעה'} value={this.state.days[3].startHour} onChange={(e:any)=>this.setStartHour(e.target.value,3)}/>
                                </Col>
                                <Col hidden={this.state.days[3].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'עד שעה'} value={this.state.days[3].endHour} onChange={(e:any)=>this.setEndHour(e.target.value,3)}/>
                                </Col>


                            </Row>
                            <Row>
                                <Col >
                                    <Checkbox style={{fontSize:18}}  checked={this.state.days[4].selected}  onChange={e => this.setDay(e.target.value,4)}>י</Checkbox>
                                </Col>

                                <Col hidden={this.state.days[4].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'משעה'} value={this.state.days[4].startHour} onChange={(e:any)=>this.setStartHour(e.target.value,4)}/>
                                </Col>
                                <Col hidden={this.state.days[4].selected==false} style={{marginRight:10}}>
                                    <AppInput type={'time'} placeholder={'עד שעה'} value={this.state.days[4].endHour} onChange={(e:any)=>this.setEndHour(e.target.value,4)}/>
                                </Col>


                            </Row>
                            <Row style={{marginTop:15}}>
                                <AppButton text={this.state.editMode?'ערוך':'הוסף'} onClick={()=>this.addGroup()}/>
                            </Row>
                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddGroup));
