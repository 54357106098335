import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {AnyAction} from "redux";
import {createBrowserHistory} from "history";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import UsersTable from "../../components/usersTable";
import {fetchUsersAction} from "../../models/user/UserActions";
import IUser from "../../models/user/IUser";
import IAdmin from "../../models/admin/IAdmin";
function mapStateToProps(state:IAppState) {
    return {
        users:state.user.users,
        fetchingUsers:state.user.fetching,
        currentAdmin:state.admin.currentAdmin
    };
}

const mapDispatchToProps = {
    saveHistory:saveHistoryAction,
    fetchUsers:fetchUsersAction
};
interface IProp {
    currentAdmin:string
    saveHistory:() => AnyAction
    fetchUsers:() => AnyAction
    users:IUser[]
    fetchingUsers:boolean
}
class UsersPage extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);
        this.state = {
        };

    }

    componentWillMount() {

        this.checkAdmin();
        this.props.fetchUsers();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    moveToAddUser(){
        this.props.history.push('/users/adduser');
    }
    render() {


        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={9} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <AppButton text={'הוסף משתמש חדש'} onClick={()=>this.moveToAddUser()}/>
                            </Row>

                            <UsersTable loading={this.props.fetchingUsers} data={this.props.users.length>0?this.props.users:[]}/>

                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersPage));

