import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {AnyAction} from "redux";
import {createBrowserHistory} from "history";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import UsersTable from "../../components/usersTable";
import {fetchUsersAction} from "../../models/user/UserActions";
import IUser from "../../models/user/IUser";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {fetchGroupsAction, fetchGroupUsersAction} from "../../models/group/GroupActions";
import IGroup from "../../models/group/IGroup";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import SelectUserModal from "../../components/modals/SelectUserModal";
import {
    fetchPushNotificationsAction,
    fetchPushNotificationsActionUsers
} from "../../models/pushNotifications/PushNotificationActions";
import IPushNotifications from "../../models/pushNotifications/IPushNotifications";
function mapStateToProps(state:IAppState) {
    return {
        users:state.user.users,
        fetchingUsers:state.user.fetching,
        groups:state.group.groups,
        notifications:state.PushNotifications.notifications
    };
}

const mapDispatchToProps = {
    saveHistory:saveHistoryAction,
    fetchUsers:fetchUsersAction,
    fetchGroupsAction:fetchGroupsAction,
    fetchGroupUsersAction:fetchGroupUsersAction,
    fetchPushNotificationsAction:fetchPushNotificationsAction,
    fetchPushNotificationsActionUsers:fetchPushNotificationsActionUsers

};
interface IProp {
    groups:IGroup[],
    notifications:IPushNotifications[]
    saveHistory:() => AnyAction
    fetchUsers:() => AnyAction
    fetchGroupsAction:()=>AnyAction
    fetchGroupUsersAction:(groupId:number,callBack:Function)=>AnyAction
    users:IUser[]
    fetchingUsers:boolean
    fetchPushNotificationsAction:()=>AnyAction;
    fetchPushNotificationsActionUsers:(pushId:number,callBack:Function)=>AnyAction;
}
class PushNotificationsUsersPage extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);


    }
    state={
        option:'0',
        groupUsers:[],
        selectedUsersIds:[],
        show:false,
        data:[],
    }
    componentWillMount() {
        this.checkAdmin();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("push")){
            let push:IPushNotifications=localState.push;
            if(push.id)
                this.props.fetchPushNotificationsActionUsers(push.id,this.handleUsers.bind(this))
            return;
        }
    }
    handleUsers(data:any[]){
        this.setState({data:data})
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    getRowText(text:string){

        return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{text}</p>;
    }
    getStatusRowText(text:string){
        if(text=="0")
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{'נשלחה'}</p>;
        if(text=="1")
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{'התקבלה'}</p>;
        if(text=="2")
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{'אושרה'}</p>;
    }
    render() {
        const data:IPushNotifications[]=this.props.notifications;
        data.forEach(u=>u.key=u.id)
        const columns = [
            {
                title: 'שם פרטי',
                dataIndex: 'user.firstName',
                key: 'msg',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: 'שם משפחה',
                dataIndex: 'user.lastName',
                key: 'createdAt',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'תעודת זהות',
                dataIndex: 'user.personId',
                key: 'personID',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.personID.localeCompare( b.personID)
            },
            {
                title: 'מצב הודעה',
                dataIndex: 'status',
                key: 'personID',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getStatusRowText(text);
                },
                //sorter: (a:any, b:any) => a.personID.localeCompare( b.personID)
            },

        ];

        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}   dataSource={this.state.data} columns={columns} />

                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PushNotificationsUsersPage));

