import {Dispatch} from "redux";
import IQuestion from "./IQuestion";

export function fetchQuestionAction() {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'question/fetch',
        });
    };

}
export function setQuestionAnswerAction(question:IQuestion) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'question/setAnswer',
            payload:{
                question:question
            }
        });
    };

}
export function deleteQuestionAction(question:IQuestion) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'question/deleteQuestion',
            payload:{
                question:question
            }
        });
    };

}
