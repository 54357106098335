import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {IAppState} from "../../models/app/AppState";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {AnyAction} from "redux";
import {deleteSessionAction, disActiveSessionAction, fetchSessionsAction} from "../../models/session/SessionActions";
import {ISession} from "../../models/session/ISession";




function mapStateToProps(state:IAppState) {
    return {
        sessions:state.session.sessions,
        loading:state.session.loading
    };
}

const mapDispatchToProps = {
    fetchSessionsAction:fetchSessionsAction,
    deleteSessionAction:deleteSessionAction,
    disActiveSessionAction:disActiveSessionAction
};
interface IProp {
    sessions:ISession[]
    loading:boolean,
    fetchSessionsAction:()=>AnyAction
    deleteSessionAction:(session:ISession)=>AnyAction
    disActiveSessionAction:(session:ISession)=>AnyAction

}
class SessionsPage extends Component<IProp&RouteComponentProps> {
    constructor(props:any) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(): void {
        this.checkAdmin();
        this.props.fetchSessionsAction();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    moveToAddSession(){
        this.props.history.push('/sessions/addsession')
    }
    getRowText(text:string){
        return <p style={{float:'right',margin:0,padding:0,fontSize:16}}>{text}</p>;
    }
    getStatusRowText(status:boolean){
        if(status==true)
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'green'}}>{"פעיל"}</p>;
        return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'red'}}>{"לא פעיל"}</p>;
    }
    deleteSession(session:ISession){
        this.props.deleteSessionAction(session);
    }
    editSession(session:ISession){
        this.props.history.push({pathname:'/sessions/addsession',state:{session:session}});
    }
    disActiveSession(session:ISession){
        this.props.disActiveSessionAction(session)
    }
    getActionsButtons(row:ISession){
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deleteSession(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'ערוך'} color={'#16B5F1'} onClick={()=>this.editSession(row)}/>
                    </Col>
                    <Col style={{marginTop:5}}>
                        <AppButton width={80} text={row.status?'השבת':'הפעל'} onClick={()=>this.disActiveSession(row)}/>
                    </Col>
                </Row>
            </div>
        )
    }
    render() {

        const columns = [
            {
                title: 'כותרת',
                dataIndex: 'title',
                key: 'title',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: 'תאריך הוספה',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'פעיל עד תאריך',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'קבוצה',
                dataIndex: 'group.name',
                key: 'group.name',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'רמה',
                dataIndex: 'group.level',
                key: 'level',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
                sorter: (a:any, b:any) => a.level-b.level,
                filters: [
                    {
                        text: '1',
                        value: '1',
                    },
                    {
                        text: '2',
                        value: '2',
                    },
                ],
                filterMultiple: false,
                onFilter: (value:any, record:any) => record.level.indexOf(value) === 0,

            },
            {
                title: 'מצב',
                dataIndex: 'status',
                key: 'status',
                align:'right' as 'right',
                filters: [
                    {
                        text: 'פעיל',
                        value: 'פעיל',
                    },
                    {
                        text: 'לא פעיל',
                        value: 'לא פעיל',
                    },
                ],
                render: (text:any, row:any, index:any) => {
                    return this.getStatusRowText(text);
                },
                filterMultiple: false,
                onFilter: (value:any, record:any) => record.status.indexOf(value) === 0,
                sorter: (a:any, b:any) => a.status.localeCompare(b.status)
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];

        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <AppButton text={'הוסף מפגש חדש'} onClick={()=>this.moveToAddSession()}/>
                            </Row>

                            <div style={{marginTop:30}}>
                                <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}  rowSelection={{
                                    type: 'checkbox'}} dataSource={this.props.sessions} columns={columns} />
                            </div>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SessionsPage));
