import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {IAppState} from "../../models/app/AppState";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {AnyAction} from "redux";
import {deleteSessionAction, disActiveSessionAction, fetchSessionsAction} from "../../models/session/SessionActions";
import {ISession} from "../../models/session/ISession";
import IGroup from "../../models/group/IGroup";
import {
    deleteGroupAction,
    deleteGroupsAction,
    disActiveGroupAction,
    fetchGroupsAction
} from "../../models/group/GroupActions";
import IUser from "../../models/user/IUser";




function mapStateToProps(state:IAppState) {
    return {
        loading:state.session.loading,
        groups:state.group.groups,
    };
}

const mapDispatchToProps = {
    fetchGroupsAction:fetchGroupsAction,
    deleteGroupAction:deleteGroupAction,
    disActiveGroupAction:disActiveGroupAction,
    deleteGroupsAction:deleteGroupsAction
};
interface IProp {
    groups:IGroup[],
    loading:boolean,
    fetchGroupsAction:()=>AnyAction
    deleteGroupAction:(group:IGroup)=>AnyAction
    disActiveGroupAction:(group:IGroup)=>AnyAction
    deleteGroupsAction:(users:[])=>AnyAction

}
interface IState {
    selected:[]
    q:string
}
class GroupsPage extends Component<IProp&RouteComponentProps&IState> {
    constructor(props:any) {
        super(props);

    }
    state:IState = {
        selected:[],
        q:'',
    };
    componentDidMount(): void {
        this.checkAdmin();
        this.props.fetchGroupsAction();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    moveToAddGroup(){
        this.props.history.push('/groups/addgroup')
    }
    getRowText(text:string){

        return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{text}</p>;
    }
    getStatusRowText(status:boolean){
        if(status==true)
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'green'}}>{"פעיל"}</p>;
        return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'red'}}>{"לא פעיל"}</p>;
    }
    deleteGroup(group:IGroup){
        //this.props.deleteSessionAction(session);
        this.props.deleteGroupAction(group);
    }
    editGroup(group:IGroup){
        this.props.history.push({pathname:'/groups/addgroup',state:{group:group}});
    }
    moveToGroupSessions(group:IGroup){
        this.props.history.push({pathname:'/groups/sessions',state:{group:group}});
    }
    getActionsButtons(row:IGroup){
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deleteGroup(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'ערוך'} color={'#16B5F1'} onClick={()=>this.editGroup(row)}/>
                    </Col>
                    
                </Row>
            </div>
        )
    }
    onRowSelect(selectedRowKeys:any){
        this.setState({selected:selectedRowKeys})

    }
    deleteGroups(){
        this.props.deleteGroupsAction(this.state.selected)
    }
    render() {
        const data:IGroup[]=this.props.groups;
        data.forEach(u=>u.key=u.id)
        const columns = [
            {
                title: 'כותרת',
                dataIndex: 'name',
                key: 'name',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: 'תאריך הוספה',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'ימי למידה',
                dataIndex: 'days',
                key: 'days',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'מספר תלמידים מקסימלי',
                dataIndex: 'maxStudents',
                key: 'maxStudents',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'מספר מקומות פנויים',
                dataIndex: 'emptySeats',
                key: 'emptySeats',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'רמה',
                dataIndex: 'level',
                key: 'level',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
                sorter: (a:any, b:any) => a.level-b.level,
                filters: [
                    {
                        text: '1',
                        value: '1',
                    },
                    {
                        text: '2',
                        value: '2',
                    },
                ],
                filterMultiple: false,
                onFilter: (value:any, record:any) => record.level.indexOf(value) === 0,

            },
            {
                title: 'מצב',
                dataIndex: 'status',
                key: 'status',
                align:'right' as 'right',
                filters: [
                    {
                        text: 'פעיל',
                        value: 'פעיל',
                    },
                    {
                        text: 'לא פעיל',
                        value: 'לא פעיל',
                    },
                ],
                render: (text:any, row:any, index:any) => {
                    return this.getStatusRowText(text);
                },
                filterMultiple: false,
                onFilter: (value:any, record:any) => record.status.indexOf(value) === 0,
                sorter: (a:any, b:any) => a.status.localeCompare(b.status)
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];

        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <AppButton text={'הוסף קבוצה חדשה'} onClick={()=>this.moveToAddGroup()}/>
                            </Row>

                            <div style={{marginTop:30}}>
                                <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}  rowSelection={{selectedRowKeys:this.state.selected,onChange:(selectedRowKeys)=>this.onRowSelect(selectedRowKeys),
                                    type: 'checkbox'}} dataSource={this.props.groups} columns={columns} />
                            </div>
                            <Row >
                                <AppButton text={'מחק מסומנים'} color={'red'} onClick={()=>this.deleteGroups()}/>
                            </Row>

                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupsPage));
