import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import UsersTable from "../../components/usersTable";
import SelectUserModal from "../../components/modals/SelectUserModal";
import {RouteComponentProps, withRouter} from "react-router";
import {IAppState} from "../../models/app/AppState";
import IUser from "../../models/user/IUser";
import IFlashCard from "../../models/flashcard/IFlashCard";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import IGroup from "../../models/group/IGroup";
import {AnyAction} from "redux";
import {fetchGroupsAction, fetchGroupUsersAction} from "../../models/group/GroupActions";
import {ISession} from "../../models/session/ISession";
import FlashCardsTable from "../../components/flashCardsTable";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import {addSessionAction} from "../../models/session/SessionActions";
import ISentence from "../../models/sentences/ISentence";
import moment from "moment";
import 'moment/locale/en-il';
function mapStateToProps(state:IAppState) {
    return {
        flashCards:state.flashcard.flashCards,
        users:state.user.users,
        groups:state.group.groups,
    };
}

const mapDispatchToProps = {
    fetchFlashCards:fetchFlashCardsAction,
    fetchGroupsAction:fetchGroupsAction,
    fetchGroupUsersAction:fetchGroupUsersAction,
    addSessionAction:addSessionAction
};
interface IProp {
    users:IUser[]
    flashCards:IFlashCard[]
    groups:IGroup[]
    fetchGroupsAction:()=>AnyAction
    fetchFlashCards:()=>AnyAction
    fetchGroupUsersAction:(groupId:number,callBack:Function)=>AnyAction
    addSessionAction:(session:ISession,users:[],cards:[],callBack:Function)=>AnyAction
}
interface IState {
    show:boolean;
    session:ISession,
    groupUsers:IUser[],
    selectedUsersIds:[]
    selectedCardsIds:[]
    error?:string;
    success?:string;
}
class AddSession extends Component<IState&IProp&RouteComponentProps> {
    constructor(props:any) {
        super(props);

    }
    state:IState={
        show:false,
        session:{title:'',groupId:-1,status:true},
        groupUsers:[],
        selectedUsersIds:[],
        selectedCardsIds:[]

    }
    componentWillMount() {
        this.checkAdmin();
        this.props.fetchGroupsAction();
        this.props.fetchFlashCards();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("session")){
            let session=localState.session;
            this.setState({session:session})
            return;
        }
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    setGroup(val:number){
        if(val!=-1){
            this.props.fetchGroupUsersAction(val,this.handleGroupUser.bind(this));
        }
        let s=this.state.session;
        s.groupId=val;
        this.setState({session:s})
    }
    handleGroupUser(users:IUser[]){
        this.setState({groupUsers:users})
    }
    componentDidUpdate(prevProps: Readonly<IState & IProp>, prevState: Readonly<{}>, snapshot?: any): void {
        //if(this.props.groups!=prevProps.groups){
           // alert(JSON.stringify(this.props.groups))
        //}
    }

    setTitle(val:string){
        let s=this.state.session;
        s.title=val;
        this.setState({session:s})
    }
    selectionCallBack(users:IUser[]){
        this.setState({show:false,groupUsers:[...this.state.groupUsers,...users]})
    }
    setSelectedUsers(users:[]){
        this.setState({selectedUsersIds:users});
    }
    setSelectedCars(cards:[]){
        this.setState({selectedCardsIds:cards});
    }
    addSession(){
        //alert(JSON.stringify(this.state.session))
        this.props.addSessionAction(this.state.session,this.state.selectedUsersIds,this.state.selectedCardsIds,this.handleAddUsers.bind(this))
    }
    handleAddUsers(success:boolean,msg:string){
        if(success){
            this.setState({success:msg})
        }else{
            this.setState({error:msg})
        }
    }
    setExpDate(val:string){
        let s=this.state.session;
        s.expirationDate=val;
        this.setState({session:s})
    }
    getExpDate(){
        if(!this.state.session.expirationDate){
            moment.locale('en-il');
           let date= moment().add(10, 'days').format('YYYY-MM-DD');
            this.setExpDate(date)

           return  date
        }

        return this.state.session.expirationDate;
    }
    render() {
        const options:IKeyValue[]=[];
        if(this.props.groups){
            this.props.groups.forEach((g:IGroup)=>options.push({key:g.id,value:g.name}))
        }
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'כותרת'} value={this.state.session.title} onChange={(event:any)=>this.setTitle(event.target.value)}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>

                                    {
                                    <AppSelect value={this.state.session.groupId} options={options} placeholder={'קבוצה'} onChange={(event:any)=>this.setGroup(event.target.value)}/>
                                    }
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'תארייך ביטול'} type={'DATE'} value={this.getExpDate()} onChange={(event:any)=>this.setExpDate(event.target.value)}/>
                                </Col>
                            </Row>
                            <h4 style={{textAlign:'right',marginTop:20}}>משתמשים</h4>
                            <Row style={{paddingRight:10}}>
                                <AppButton text={'הוסף משתמשים'} onClick={()=>this.setState({show:true})}/>
                            </Row>
                            <SelectUserModal selectionCallBack={(users:IUser[])=>this.selectionCallBack(users)} show={this.state.show} data={this.props.users} closeCallBack={()=>this.setState({show:false})} currentSelectedUser={this.state.groupUsers}/>
                            <UsersTable selectionCallBack={this.setSelectedUsers.bind(this)} data={this.state.groupUsers} loading={false} withActions={false} />

                            <h4 style={{textAlign:'right',marginTop:20}}>כרטסיות</h4>
                            <FlashCardsTable  selectionCallBack={this.setSelectedCars.bind(this)} data={this.props.flashCards} loading={false} withActions={false}/>

                            <Row style={{paddingRight:10,marginTop:30,marginBottom:50}}>
                                <AppButton text={'הוסף מפגש'} onClick={()=>this.addSession()}/>
                            </Row>
                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSession));
