import {Dispatch} from "redux";
import ISentence from "../sentences/ISentence";
import {ISession} from "./ISession";
import IFlashCard from "../flashcard/IFlashCard";
export function fetchSessionsAction() {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'session/fetch',
        });
    };

}
export function addSessionAction(session:ISession,users:[],cards:[],callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'session/add',
            payload:{
                session:session,
                users:users,
                cards:cards,
                callBack:callBack
            }
        });
    };

}
export function deleteSessionAction(session:ISession) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'session/deleteSession',
            payload:{
                session:session,
            }
        });
    };

}
export function disActiveSessionAction(session:ISession) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'session/disActiveSession',
            payload:{
                session:session,
            }
        });
    };
}
