import IFlashCard from "../flashcard/IFlashCard";
import ISentence from "../sentences/ISentence";
import HttpRequest from "../../utils/HttpRequest";

function fetch() {
    return HttpRequest('pushNotifications',"GET");
}
function fetchUser(pushId:number) {
    return HttpRequest('pushNotifications/users?pushId='+pushId,"GET");
}

export default {fetch,fetchUser}
