import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppInput from "../components/appInput";
import AppButton from "../components/appButton";
import {IAppState} from "../models/app/AppState";
import {saveHistoryAction} from "../models/app/AppActions";
import {fetchUsersAction} from "../models/user/UserActions";
import {AnyAction} from "redux";
import IUser from "../models/user/IUser";
import {RouteComponentProps} from "react-router";
import {loginAction} from "../models/admin/IAdminAction";
function mapStateToProps(state:IAppState) {
  return {

  };
}

const mapDispatchToProps = {
  loginAction:loginAction
};
interface IProp {
  loginAction:(userName:string,password:string,callBack:Function)=>AnyAction
}
class IndexPage extends Component<IProp & RouteComponentProps>  {
  constructor(props:any) {
    super(props);
  }
  state={
    userName:'',
    password:'',
    error:'',
  }
  componentWillMount() {
    localStorage.removeItem('token')
  }
  login(){
    this.setState({error:''})
    if(this.state.password=='' || this.state.userName==''){
      this.setState({error:'נא להכניס שם משתמש וסיסמה'})
      return;
    }
    this.props.loginAction(this.state.userName,this.state.password,this.handleLogin.bind(this))
  }
  handleLogin(success:boolean){
    if(!success){
      this.setState({error:'משתמש לא קיים'});
      return;
    }
    this.props.history.push('/users');
  }
  render() {
    return (
      <div style={{direction:'rtl'}}>
        <Grid fluid style={{padding:0}}>
          <Row style={{justifyContent:'center',marginTop:130}}>
            <img src={require('../assets/logo.png')} id='headerLogo' style={{width:400}}/>
          </Row>
          <Row style={{justifyContent:'center',marginTop:70}}>
            <div style={{width:300}}>
              <AppInput placeholder={'שם משתמש'} onChange={(event:any)=>this.setState({userName:event.target.value})}/>
            </div>
          </Row>
          <Row style={{justifyContent:'center',marginTop:10}}>
            <div style={{width:300}}>
              <AppInput type={'password'} placeholder={'סיסמה'} onChange={(event:any)=>this.setState({password:event.target.value})}/>
            </div>
          </Row>
          <Row style={{justifyContent:'center',marginTop:10}}>
            <AppButton  text={'כניסה'} onClick={()=>this.login()}/>
          </Row>
          <Row hidden={!this.state.error} style={{justifyContent:'center',marginTop:10}}>
            <p style={{fontSize:22,color:'red'}}>{this.state.error}</p>
          </Row>
        </Grid>
      </div>
    );
  }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
