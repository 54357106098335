import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'antd/dist/antd.css';
import IUser from "../../models/user/IUser";
import {IAppState} from "../../models/app/AppState";
import {RouteComponentProps, withRouter} from "react-router";
import {
    deleteRegisteredSessionAction,
    fetchUserSessionsAction,
    registerToSessionAction
} from "../../models/user/UserActions";
import {AnyAction} from "redux";
import IUserRegisteredSession from "../../models/UserRegisteredSession/IUserRegisteredSession";
import {Table} from "antd";
import IGroup from "../../models/group/IGroup";
import AppButton from "../../components/appButton";
import {fetchGroupsAction, fetchGroupSessionsAction} from "../../models/group/GroupActions";
import AppSelect from "../../components/appSelect";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import AppInput from "../../components/appInput";
import moment from "moment";

function mapStateToProps(state:IAppState) {
    return {

        currentGroupSessions:state.group.currentGroupSessions,
        groups:state.group.groups
    };
}

const mapDispatchToProps = {
    fetchGroupSessionsAction:fetchGroupSessionsAction,
    fetchGroupsAction:fetchGroupsAction,
};
interface IProp{
    currentGroupSessions:IUserRegisteredSession[]
    fetchGroupSessionsAction:(id:number)=>AnyAction
    fetchGroupsAction:()=>AnyAction
}

interface IState {
    group?:IGroup
    showAddNewSession:boolean;
    newSession:IUserRegisteredSession;
    error?:boolean;
    success?:boolean;
}
class GroupSessions extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);

    }
    state:IState={
        newSession:{date:new Date(),numOfSession:0},
        showAddNewSession:false
    }
    componentWillMount() {
        this.checkAdmin();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("group")){
            let group=localState.group;
            this.props.fetchGroupSessionsAction(group.id);
            this.setState({group:group,editMode:true})
            return;
        }

    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    getRowText(text:string){

        return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{text}</p>;
    }
    deleteRegisteredSession(session:IUserRegisteredSession){
        //this.props.deleteRegisteredSessionAction(session);
    }
    getActionsButtons(row:IUserRegisteredSession){
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deleteRegisteredSession(row)}/>
                    </Col>
                </Row>
            </div>
        )
    }
    setDate(date:Date){
        let s=this.state.newSession;
        s.date=date;
        this.setState({newSession:s,error:null});

            let mom=moment(date);
            let group=this.state.group
            if(group!=null){
                let days=group.GroupSessionsDays.filter(g=>g.day==(mom.day()+1))
                if(days==null || days.length==0)
                    this.setState({error:'קבוצה זו לא לומדת ביום שבחרת'})
            }




    }
    showAddSession(){
        this.setState({showAddNewSession:!this.state.showAddNewSession})
    }
    register(){

    }
    render() {
        const columns = [
            {
                title: 'תאריך מפגש',
                dataIndex: 'date',
                key: 'date',
                align:'right' as 'right',
                render: (text:string, row:any, index:any) => {
                    return this.getRowText(text.substring(0,10));
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },

            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];
        /*const options:IKeyValue[]=[];
        if(this.props.groups){
            this.props.groups.forEach((g:IGroup)=>options.push({key:g.id,value:g.name}))
        }*/
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <h3>{this.state.group&&(this.state.group.name)}</h3>
                            </Row>
                            <Row>
                                <AppButton text={'הוסף מפגש חדש'} onClick={()=>this.showAddSession()}/>
                            </Row>
                            <Row hidden={this.state.showAddNewSession==false}>
                                <Col lg={4}>
                                    <AppInput placeholder={'תאריך התחלה'}   value={this.state.newSession.date} type={'date'} onChange={(event:any)=>this.setDate(event.target.value)}/>
                                </Col>

                            </Row>
                            <Row hidden={this.state.showAddNewSession==false} style={{marginTop:15,marginRight:5}}>
                                <AppButton   width={100} text={'הוסף'} onClick={()=>this.register()}/>
                            </Row>
                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                            <div style={{marginTop:30}}>
                                <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}
                                        dataSource={this.props.currentGroupSessions} columns={columns} />
                            </div>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupSessions));
