import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {AnyAction} from "redux";
import {createBrowserHistory} from "history";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import UsersTable from "../../components/usersTable";
import {fetchUsersAction} from "../../models/user/UserActions";
import IUser from "../../models/user/IUser";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {fetchGroupsAction, fetchGroupUsersAction} from "../../models/group/GroupActions";
import IGroup from "../../models/group/IGroup";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import SelectUserModal from "../../components/modals/SelectUserModal";
import {
    fetchPushNotificationsAction,
    fetchPushNotificationsActionUsers
} from "../../models/pushNotifications/PushNotificationActions";
import IPushNotifications from "../../models/pushNotifications/IPushNotifications";
function mapStateToProps(state:IAppState) {
    return {
        users:state.user.users,
        fetchingUsers:state.user.fetching,
        groups:state.group.groups,
        notifications:state.PushNotifications.notifications
    };
}

const mapDispatchToProps = {
    saveHistory:saveHistoryAction,
    fetchUsers:fetchUsersAction,
    fetchGroupsAction:fetchGroupsAction,
    fetchGroupUsersAction:fetchGroupUsersAction,
    fetchPushNotificationsAction:fetchPushNotificationsAction,


};
interface IProp {
    groups:IGroup[],
    notifications:IPushNotifications[]
    saveHistory:() => AnyAction
    fetchUsers:() => AnyAction
    fetchGroupsAction:()=>AnyAction
    fetchGroupUsersAction:(groupId:number,callBack:Function)=>AnyAction
    users:IUser[]
    fetchingUsers:boolean
    fetchPushNotificationsAction:()=>AnyAction;

}
class PushNotificationsPage extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);


    }
    state={
        option:'0',
        groupUsers:[],
        selectedUsersIds:[],
        show:false
    }
    componentWillMount() {
        this.checkAdmin();
        this.props.fetchGroupsAction();
        this.props.fetchUsers();
        this.props.fetchPushNotificationsAction();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    moveToAddUser(){
        this.props.history.push('/users/adduser');
    }
    setGroup(val:string){
        this.setState({option:val+""})
        if(val!="0"){
            this.props.fetchGroupUsersAction(parseInt(val),this.handleGroupUser.bind(this));
        }
    }
    handleGroupUser(users:IUser[]){
        this.setState({groupUsers:users})
    }
    selectionCallBack(users:IUser[]){
        this.setState({show:false,groupUsers:[...this.state.groupUsers,...users]})
    }
    setSelectedUsers(users:[]){
        this.setState({selectedUsersIds:users});
    }
    getRowText(text:string){

        return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{text}</p>;
    }
    deletePush(push:IPushNotifications){

    }
    goToUsersList(push:IPushNotifications){
        this.props.history.push({pathname:'/pushnotifications/PushNotificationsUsersPage',state:{push:push}});
    }
    getActionsButtons(row:IPushNotifications){
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={140} text={'רשימת משתמשים'} color={'green'} onClick={()=>this.goToUsersList(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deletePush(row)}/>
                    </Col>

                </Row>
            </div>
        )
    }
    render() {
        const data:IPushNotifications[]=this.props.notifications;
        data.forEach(u=>u.key=u.id)
        const columns = [
            {
                title: 'הודעה',
                dataIndex: 'msg',
                key: 'msg',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: 'תאריך הוספה',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                //sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];

        const options:IKeyValue[]=[{key:0,value:'כל המשתמשים'}];
        //options=[...options,]
        if(this.props.groups){
            this.props.groups.forEach((g:IGroup)=>options.push({key:g.id,value:g.name}))
        }
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <div style={{width:'30%'}}>
                                    <AppInput type={'mu'} placeholder={'תוכן ההודעה'} value={''}/>
                                </div>
                            </Row>
                            <Row>
                                <div style={{width:'30%'}}>
                                    <AppSelect    options={options} value={this.state.option}  onChange={(event:any)=>this.setGroup(event.target.value)}/>
                                </div>
                            </Row>

                            <div style={{display:this.state.option=='0'?'none':'flex',flexDirection:'column'}}>
                                <Row style={{paddingRight:10,marginTop:20}}>
                                    <AppButton text={'הוסף משתמשים'} onClick={()=>this.setState({show:true})}/>
                                </Row>
                                <UsersTable selectionCallBack={this.setSelectedUsers.bind(this)}  data={this.state.groupUsers} loading={false} withActions={false} />
                            </div>
                            <SelectUserModal selectionCallBack={(users:IUser[])=>this.selectionCallBack(users)} show={this.state.show} data={this.props.users} closeCallBack={()=>this.setState({show:false})} currentSelectedUser={this.state.groupUsers}/>
                            <Row style={{paddingRight:10,marginTop:20}}>
                                <AppButton text={'שלח'} onClick={()=>alert(this.state.selectedUsersIds)}/>
                            </Row>

                            <Row>
                                <h2>הודעות אחרונות</h2>
                            </Row>
                            <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}   dataSource={this.props.notifications} columns={columns} />
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PushNotificationsPage));

