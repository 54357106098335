import {ISession} from "../session/ISession";
import {Dispatch} from "redux";

export function deleteLessonAction(groupId:number,date:string,sendPush:boolean,pushText:string,callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'UserRegisteredSession/delete',
            payload:{
                groupId:groupId,
                date:date,
                sendPush:sendPush,
                pushText:pushText,
                callBack:callBack,

            }
        });
    };

}
export function replaceLessonAction(groupId:number,date:string,newDate:string,sendPush:boolean,pushText:string,callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'UserRegisteredSession/replace',
            payload:{
                groupId:groupId,
                date:date,
                newDate:newDate,
                sendPush:sendPush,
                pushText:pushText,
                callBack:callBack,

            }
        });
    };

}
export function fetchGroupsByLessonDate(date:string,callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'UserRegisteredSession/fetch',
            payload:{
                date:date,
                callBack:callBack,

            }
        });
    };

}
