

import GroupState from "./GroupState";
import IGroup from "./IGroup";
import FlashCardServices from "../flashcard/FlashCardServices";
import GroupServices from "./GroupServices";
import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import IFlashCard from "../flashcard/IFlashCard";


interface IAppModel{
    namespace:string
    state:GroupState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const GroupModel:IAppModel={
    namespace: 'group',
    state: {
        groups:[],
        loading:false,
        currentGroupSessions:[]
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){

            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(GroupServices.fetchGroups);
                let groups:IGroup[]=res.data;
                for(let i=0;i<groups.length;i++){
                    if(groups[i].GroupSessionsDays!=null){
                        let str='';
                        // @ts-ignore
                       for(let j=0;j<groups[i].GroupSessionsDays.length;j++){
                           console.log(j+"");

                           let dayName='';
                           // @ts-ignore
                           let day=groups[i].GroupSessionsDays[j].day;
                           if(day==1){
                               dayName="א";
                           }
                           if(day==2){
                               dayName="ב";
                           }
                           if(day==3){
                               dayName="ג";
                           }
                           if(day==4){
                               dayName="ד";
                           }
                           if(day==5){
                               dayName="י";
                           }
                           // @ts-ignore
                           str=str+dayName+ " "+groups[i].GroupSessionsDays[j].startHour+ " עד "+groups[i].GroupSessionsDays[j].endHour+"\n"
                       }
                       groups[i].days=str;
                    }

                }
                yield put({type: 'updateState', payload: {groups:groups }});
            }catch (e) {

            }finally {
                yield put({type: 'updateState', payload: {loading: false}});
            }
        },
        *fetchGroupUsers({payload}:any,{select,call,put}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let groupId=payload.groupId;
                let res=yield call(GroupServices.fetchGroupUsers,groupId);
                let users:IUser[]=res.data;
                let groups:IGroup[]=yield select((state:IAppState) => state.group.groups);
                for(let i=0;i<groups.length;i++){
                    if(groups[i].id==groupId){
                        groups[i].users=users;
                    }
                }
                payload.callBack(users);
                yield put({type: 'updateState', payload: { groups: groups } });
            }catch (e) {

            }finally {


                yield put({type: 'updateState', payload: {loading: false}});
            }
        },
        *addGroup({payload}:any,{call,put}:any){
            yield put({type: 'updateState', payload: {fetching: true}});
            try{
                let res=yield call(GroupServices.addGroup,payload.group,payload.days);
                yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
                payload.callBack(true,"פעולה בוצעה בהצלחה")
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {fetching: false}});

            }

        },
        *updateGroup({payload}:any,{call,put,select}:any){

            yield put({type: 'updateState', payload: {fetching: true}});
            try{
                let res=yield call(GroupServices.updateGroup,payload.group,payload.days);
                yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
                payload.callBack(true,"פעולה בוצעה בהצלחה")
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {fetching: false}});

            }
        },
        *deleteGroup({payload}:any,{call,put,select}:any){
            yield call(GroupServices.deleteGroup,payload.group);
            let groups:IGroup[]=yield select((state:IAppState) => state.group.groups);
            groups=groups.filter(g=>g.id!=payload.group.id)
            yield put({type: 'updateState', payload: {groups: groups}});
        },
        *disActiveGroup({payload}:any,{call,put,select}:any){
            let group=payload.group;

            yield call(GroupServices.disActiveGroup,group);
            group.status=!group.status;
            let groups:IGroup[]=yield select((state:IAppState) => state.group.groups);
            groups=groups.filter(g=>g.id!=group.id)
            groups.push(group)
            yield put({type: 'updateState', payload: {groups: groups}});
        },
        *deleteGroups({payload}:any,{call,put,select}:any){
            let groupsToDelete=payload.groupsId;
            try{
                let res=yield call(GroupServices.deleteGroups,groupsToDelete);
                let groups:IGroup[]=yield select((state:IAppState) => state.group.groups);
                for(let i=0;i<groupsToDelete.length;i++){
                    groups=groups.filter(u=>u.id!=groupsToDelete[i]);
                }
                yield put({type: 'updateState', payload: {groups: groups}});
            }catch (e) {
                alert(JSON.stringify(e))
            }
        },
        *fetchGroupSessions({payload}:any,{call,put,select}:any){
            try{
                let groupId=payload.id;
                let res=yield call(GroupServices.fetchGroupSessions,groupId);
                yield put({type: 'updateState', payload: {currentGroupSessions: res.data}});
            }catch (e) {

            }
        }

    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default GroupModel
