import IUser from "../user/IUser";
import HttpRequest from "../../utils/HttpRequest";
import IFlashCard from "./IFlashCard";
import ISentence from "../sentences/ISentence";
import Axios from "axios";
function fetchFlashCards() {
    return HttpRequest('flashcards',"GET");
}
function fetchNewestByLevel(level:number) {
    return HttpRequest('flashcards/newestByLevel?level='+level,"GET");
}

function  fileUpload(flashcard:IFlashCard,sentences:ISentence[]){
    const url = 'http://localhost:3001/flashcards';
    const formData = new FormData();
    formData.append('flashcard',JSON.stringify(flashcard));
    formData.append('sentences',JSON.stringify(sentences));
    let b=new Blob([sentences[0].enVoice,sentences[1].heVoice])
    formData.append('file',b);
    let request = new XMLHttpRequest();
    request.open('POST', url);
    request.send(formData);
}

function addFlashCard(flashcard:IFlashCard,sentences:ISentence[]) {
    //return fileUpload(flashcard,sentences);
    return HttpRequest('flashcards',"POST",{flashcard:flashcard,sentences:sentences});
}
function deleteFlashCard(flashcard:IFlashCard) {
    return HttpRequest('flashcards',"DELETE",{flashcard:flashcard});
}
function disActiveFlashCard(flashcard:IFlashCard) {
    return HttpRequest('flashcards/disActive',"POST",{flashcard:flashcard});
}
function fetchFlashCartSentences(id:number) {
    return HttpRequest('flashcards/sentences?id='+id,"GET");
}
function updateFlashCard(flashcard:IFlashCard,sentences:ISentence[]) {
    return HttpRequest('flashcards',"PATCH",{flashcard:flashcard,sentences:sentences});
}
export default {addFlashCard,fetchFlashCards,deleteFlashCard,disActiveFlashCard,fetchFlashCartSentences,updateFlashCard,fetchNewestByLevel}
