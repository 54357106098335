import React, { Component } from 'react';
import {Table} from "antd";
import {Col, Row} from "react-flexbox-grid";
import AppButton from "../appButton";
import AppInput from "../appInput";
import IUser from "../../models/user/IUser";
import {ColumnProps} from "antd/es/table";
import {IAppState} from "../../models/app/AppState";
import {
    addUserAction,
    deleteUserAction, deleteUsersAction, disActiveUserAction,
    fetchInsertedUserAction,
    saveInsertedUserAction
} from "../../models/user/UserActions";
import {AnyAction} from "redux";
import {connect} from "dva";
import {RouteComponentProps, withRouter} from "react-router";
interface IProps {
    data?:any
    withActions:boolean
    loading:boolean
}
function mapStateToProps(state:IAppState) {
    return {
        insertedUser:state.user.insertedUser,
        loading:state.user.fetching
    };
}

const mapDispatchToProps = {
   deleteUser:deleteUserAction,
    disActiveUser:disActiveUserAction,
    deleteUsers:deleteUsersAction
};
interface IProps{
    disActiveUser:(user:IUser)=>AnyAction
    deleteUser:(user:IUser)=>AnyAction
    deleteUsers:(users:[])=>AnyAction
    selectionCallBack?:Function
}
interface IState {
    selected:[]
    q:string
}
 class UsersTable extends Component<IProps & RouteComponentProps>{
    state:IState={
        selected:[],
        q:''
    }
    getRowText(text:string){
        return <p style={{float:'right',margin:0,padding:0,fontSize:16}}>{text}</p>;
    }
    getStatusRowText(status:boolean){
        if(status==true)
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'green'}}>{"פעיל"}</p>;
        return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'red'}}>{"לא פעיל"}</p>;
    }
    deleteUser(user:IUser){
        this.props.deleteUser(user);
    }
    disActiveUser(user:IUser){
        this.props.disActiveUser(user);
    }
    editUser(user:IUser){
        this.props.history.push({pathname:'/users/adduser',state:{user:user}});
    }
    moveToUserSessions(user:IUser){
        this.props.history.push({pathname:'/users/sessions',state:{user:user}});
    }
    getActionsButtons(row:IUser){
        if(this.props.withActions!=undefined && this.props.withActions==false)
            return <></>
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={60} text={'מחק'} color={'red'} onClick={()=>this.deleteUser(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={60} text={'ערוך'} color={'#16B5F1'} onClick={()=>this.editUser(row)}/>
                    </Col>
                    <Col style={{marginTop:5}}>
                        <AppButton width={60} text={row.status?'השבת':'הפעל'} onClick={()=>this.disActiveUser(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginRight:5}}>
                        <AppButton width={120} text={'רשימת מפגשים'} color={'#F8AF00'} onClick={()=>this.moveToUserSessions(row)}/>
                    </Col>
                </Row>
            </div>
        )
    }
    onRowSelect(selectedRowKeys:any){
        this.setState({selected:selectedRowKeys})
        if(this.props.selectionCallBack)
            this.props.selectionCallBack(selectedRowKeys);
    }
     deleteUsers(){
        this.props.deleteUsers(this.state.selected)
     }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const columns: ColumnProps<IUser>[] = [
            {
                title: 'שם פרטי',
                dataIndex: 'firstName',
                key: 'firstName',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                defaultSortOrder: 'descend',
                sorter: (a:any, b:any) => a.firstName.localeCompare( b.firstName)
            },
            {
                title: 'שם משפחה',
                dataIndex: 'lastName',
                key: 'lastName',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.lastName.localeCompare( b.lastName)
            },
            {
                title: 'תעודת זהות',
                dataIndex: 'personId',
                key: 'personID',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.personID.localeCompare( b.personID)
            },
            {
                title: 'מספא טלפון',
                dataIndex: 'phone',
                key: 'phone',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.phoneNumber.localeCompare( b.phoneNumber)
            },
            {
                title: 'דואר אליקטרוני',
                dataIndex: 'email',
                key: 'email',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.email.localeCompare( b.email)
            },
            {
                title: 'קבוצה',
                dataIndex: 'group.name',
                key: 'group.name',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:IUser, b:IUser) =>{
                    if(a.group!=undefined && b.group!=undefined){
                        return a.group.name.localeCompare( b.group.name)
                    }
                    return 0;

                }

            },
            {
                title: 'שפת ממשק',
                dataIndex: 'appLang',
                key: 'appLang',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text=="he"?'עברית':'אנגלית');
                },
            },
            {
                title: 'מצב',
                dataIndex: 'status',
                key: 'status',
                align:'right' as 'right',
                filters: [
                    {
                        text: 'פעיל',
                        value: '1',
                    },
                    {
                        text: 'לא פעיל',
                        value: '2',
                    },
                ],
                render: (text:any, row:any, index:any) => {
                    return this.getStatusRowText(text);
                },
                filterMultiple: false,
                onFilter: (value:any, record:any) => {
                    let status=false;
                    if(value=="1")
                        status=true
                    return record.status == status
                },
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];
        const data:IUser[]=this.props.data;
        data.forEach(u=>u.key=u.id)
        return (
            <div>
                <Row>
                    <Col lg={4}>
                        <AppInput placeholder={'חיפוש'} value={this.state.q} onChange={(event:any)=>this.setState({q:event.target.value})}/>
                    </Col>
                </Row>
                <Table<IUser> loading={this.props.loading}  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl',marginTop:10}}  rowSelection={{selectedRowKeys:this.state.selected,onChange:(selectedRowKeys)=>this.onRowSelect(selectedRowKeys),
                    type: 'checkbox'}} dataSource={this.state.q==''?this.props.data:this.props.data.filter((d:IUser)=>d.firstName.includes(this.state.q) || d.personId.includes(this.state.q))} columns={columns} />
                <Row hidden={this.props.withActions==false}>
                    <AppButton text={'מחק מסומנים'} color={'red'} onClick={()=>this.deleteUsers()}/>
                </Row>

            </div>
        )
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersTable))
