import {Dispatch} from "redux";
import IUser from "./IUser";
import IUserRegisteredSession from "../UserRegisteredSession/IUserRegisteredSession";

export function fetchUsersAction() {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/fetch',
        });
    };

}
export function saveInsertedUserAction(user:IUser) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/saveInsertedUser',
            payload:{
                user:user
            }
        });
    };

}
export function fetchInsertedUserAction(callBack:Function) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/fetchInsertUser',
            payload:{
                callBack:callBack
            }
        });
    };

}
export function addUserAction(user:IUser,callBack:Function) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/addUser',
            payload:{
                user:user,
                callBack:callBack
            }
        });
    };

}
export function updateUserAction(user:IUser,callBack:Function) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/updateUserAction',
            payload:{
                user:user,
                callBack:callBack
            }
        });
    };

}
export function deleteUserAction(user:IUser) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/deleteUser',
            payload:{
                user:user,
            }
        });
    };

}
export function disActiveUserAction(user:IUser) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/disActiveUser',
            payload:{
                user:user,
            }
        });
    };

}
export function deleteUsersAction(usersId:[]) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/deleteUsers',
            payload:{
                usersId:usersId,
            }
        });
    };

}
export function fetchUserSessionsAction(userId:number){
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/fetchUserSessions',
            payload:{
                userId:userId,
            }
        });
    };
}
export function registerToSessionAction(user:IUser,session:IUserRegisteredSession,callBack:Function){
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/registerToSession',
            payload:{
                user:user,
                session:session,
                callBack:callBack
            }
        });
    };
}
export function deleteRegisteredSessionAction(session:IUserRegisteredSession){
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/deleteRegisteredSession',
            payload:{
                session:session,
            }
        });
    };
}
