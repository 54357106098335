
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
const API_URL='http://apihorizon.andriosapps.net/api'
export default function request(url: string,method:any, data?: any) {

    return axios.request({
        url: url,
        baseURL: API_URL,
        method:method,
        data:data

    }).then((result: AxiosResponse) => {
        return Promise.resolve(result);

    }).catch((err: AxiosError) => {
        return Promise.reject(err.response);
    });
}
