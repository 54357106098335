



import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import SessionServices from "../session/SessionServices";
import UserRegisteredSessionServices from "./UserRegisteredSessionServices";
import UserRegisteredSessionState from "./UserRegisteredSessionState";



interface IAppModel{
    namespace:string
    state:UserRegisteredSessionState;
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const UserRegisteredSessionModel:IAppModel={
    namespace: 'UserRegisteredSession',
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    state: {

    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){

            try{
                let res=yield call(UserRegisteredSessionServices.fetchGroupsByLessonDate,payload.date);
                if(res.status==200){
                    //alert(JSON.stringify(res.data))
                    payload.callBack(res.data)
                    return ;
                }
                payload.callBack([]);
            }catch (e) {
                payload.callBack([]);
            }

        },
        *delete({payload}:any,{call,put,select}:any){

            try{
                let res=yield call(UserRegisteredSessionServices.deleteLesson,payload.groupId,payload.date,payload.sendPush,payload.pushText);
                if(res.status==200){
                    payload.callBack(true,"פעולה בוצעה בהצלחה")
                    return ;
                }
                payload.callBack(false,'שגיאת שרת');
            }catch (e) {
                payload.callBack(false,'שגיאת שרת');
            }

        },
        *replace({payload}:any,{call,put,select}:any){

            try{
                let res=yield call(UserRegisteredSessionServices.replaceLesson,payload.groupId,payload.date,payload.newDate,payload.sendPush,payload.pushText);
                if(res.status==200){
                    payload.callBack(true,"פעולה בוצעה בהצלחה")
                    return ;
                }
                payload.callBack(false,'שגיאת שרת');
            }catch (e) {
                payload.callBack(false,'שגיאת שרת');
            }

        },
    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default UserRegisteredSessionModel
