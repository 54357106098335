import request from '../../utils/request';
import HttpRequest from "../../utils/HttpRequest";
import IUser from "./IUser";
import IUserRegisteredSession from "../UserRegisteredSession/IUserRegisteredSession";

 function fetchUsers() {
    return HttpRequest('users',"GET");
}
function addUser(user:IUser) {
     //alert(JSON.stringify(user))
    return HttpRequest('users',"POST",{user:user});
}
function updateUser(user:IUser) {
    //alert(JSON.stringify(user))
    return HttpRequest('users',"PATCH",{user:user});
}
function deleteUser(user:IUser) {
    //alert(JSON.stringify(user))
    return HttpRequest('users',"DELETE",{user:user});
}
function disActiveUser(user:IUser) {
    //alert(JSON.stringify(user))
    return HttpRequest('users/disActive',"POST",{user:user});
}
function deleteUsers(ids:[]) {
    return HttpRequest('users/multiDelete',"DELETE",{ids:ids});
}
function fetchUserRegisteredSessions(id:number) {
    return HttpRequest('users/registeredSessions?id='+id,"GET");
}
function registerToSession(user:IUser,session:IUserRegisteredSession) {
    return HttpRequest('users/registeredSessions',"POST",{user:user,session:session});
}
function deleteRegisteredSession(session:IUserRegisteredSession) {
    return HttpRequest('users/registeredSessions',"DELETE",{session:session});
}
export default {fetchUsers,addUser,updateUser,deleteUser,disActiveUser,deleteUsers,fetchUserRegisteredSessions,registerToSession,deleteRegisteredSession}
