import React, { Component } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';
import AppInput from "../appInput";
import AppSelect from "../appSelect";
import { ReactMic } from 'react-mic';
import './styles.css'
import {Button} from "react-bootstrap";
import VoiceRecorder from "../voiceRecorder";
import ISentence from "../../models/sentences/ISentence";
import {connect} from "dva";
import {withRouter} from "react-router";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {
    setEnglishTextAction,
    setEnglishVoiceAction,
    setHebrewTextAction,
    setHebrewVoiceAction
} from "../../models/sentences/SentenceAction";
import {AnyAction} from "redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function mapStateToProps(state:IAppState) {
    return {
        sentences:state.sentences.sentences
    };
}

const mapDispatchToProps = {
    saveHistory:saveHistoryAction,
    setEnglishText:setEnglishTextAction,
    setHebrewText:setHebrewTextAction,
    setEnglishVoice:setEnglishVoiceAction,
    setHebrewVoice:setHebrewVoiceAction
};
interface IState {
    record:boolean
    sentence:ISentence
}
interface IProp {
    sentence:ISentence
    sentences:ISentence[];
    setEnglishText:(text:string,index:number)=>AnyAction
    setHebrewText:(text:string,index:number)=>AnyAction
    setEnglishVoice:(voice:any,index:number)=>AnyAction
    setHebrewVoice:(voice:any,index:number)=>AnyAction
}
 class SentenceInput extends Component<IProp>{
    state:IState={
        record:false,
        sentence:this.props.sentence
    }
    componentDidMount(): void {
        //alert(JSON.stringify(this.state.sentence))
    }
    componentDidUpdate(prevProps: Readonly<IProp>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.state.sentence!=this.props.sentence){
            this.setState({sentence:this.props.sentence})
        }
    }

     setEnText(text:string){
        this.props.setEnglishText(text,this.props.sentence.index)
         let s=this.state.sentence;
        s.en=text;
        this.setState({sentence:s})
     }
     setHeText(text:string){
         this.props.setHebrewText(text,this.props.sentence.index)
         let s=this.state.sentence;
         s.he=text;
         this.setState({sentence:s})
     }
     setVoice(key:string,blob:any){

        if(key=='en'){
            alert("en")
            console.log(blob)
            this.props.setEnglishVoice(blob,this.props.sentence.index)
        }
        if(key=="he"){
            alert("he")
            this.props.setHebrewVoice(blob,this.props.sentence.index)
        }
     }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        return (
            <Draggable key={this.props.sentence.index} draggableId={this.props.sentence.index+""} index={this.props.sentence.index}>
                {(provided, snapshot) => (
                    <div  key={1}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Row style={{width:'100%',borderBottomWidth:2,borderBottomColor:'#8e9094',borderBottomStyle:'solid',paddingBottom:15}}

                        >
                            <Col lg={6} md={4} sm={6} >
                                <VoiceRecorder callBack={this.setVoice.bind(this)} recorderKey={'en'} sentence={this.state.sentence}/>
                                <AppInput placeholder={'אנגלית'} value={this.state.sentence.en} onChange={(event:any)=>this.setEnText(event.target.value)}/>
                            </Col>
                            <Col lg={6} md={4} sm={6}  >
                                <VoiceRecorder callBack={this.setVoice.bind(this)} recorderKey={'he'}  sentence={this.state.sentence}/>
                                <AppInput placeholder={'עברית'} key={'mykey2'} value={this.state.sentence.he} onChange={(event:any)=>this.setHeText(event.target.value)}/>
                            </Col>
                        </Row>
                    </div>

                )
                }
            </Draggable>



        )
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SentenceInput);
