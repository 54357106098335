import dva from 'dva';
import { message } from 'antd';
import './index.css';
import thunk from "redux-thunk";
//import createLoading from 'dva-loading';

// 1. Initialize
const app = dva({
  onAction:[thunk],
  onError(e, dispatch) {
    console.log("error!", e.message);
    message.error(e.message);
  },
});

// 2. Plugins
// app.use({});
//app.use(createLoading());
// 3. Model
app.model(require('./models/app/appModel').default);
app.model(require('./models/user/userModel').default);
app.model(require('./models/sentences/sentencesModel').default);
app.model(require('./models/flashcard/flashcardModel').default);
app.model(require('./models/group/groupModel').default);
app.model(require('./models/session/SessionModel').default);
app.model(require('./models/question/QuestionModel').default);
app.model(require('./models/admin/adminModel').default);
app.model(require('./models/UserRegisteredSession/UserRegisteredSessionModel').default);
app.model(require('./models/pushNotifications/PushNotificationsModel').default);
// 4. Router
app.router(require('./router').default);

// 5. Start
app.start('#root');
