import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import SentenceInput from "../../components/sentenceInput";
import ISentence from "../../models/sentences/ISentence";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {AnyAction} from "redux";
import {RouteComponentProps, withRouter} from "react-router";
import SentencesState from "../../models/sentences/SentencesState";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setSentencesAction, swapAction} from "../../models/sentences/SentenceAction";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {
    addFlashCardAction,
    deleteFlashCardAction,
    updateFlashCardAction
} from "../../models/flashcard/FlashCardActions";
// @ts-ignore
import readXlsxFile from 'read-excel-file'
import {levels} from "../../utils/Levels";
function mapStateToProps(state:IAppState) {
    return {
        sentences:state.sentences,
        loading:state.flashcard.loading
    };
}

const mapDispatchToProps = {
    swap:swapAction,
    addFlashCard:addFlashCardAction,
    updateFlashCard:updateFlashCardAction,
    setSentencesAction:setSentencesAction,
    deleteFlashCardAction:deleteFlashCardAction
};
interface IProp {
    loading:boolean;
    sentences:SentencesState
    swap:(source:number,dest:number)=>AnyAction
    addFlashCard:(flashCard:IFlashCard,sentences:ISentence[],callBack:Function)=>AnyAction
    updateFlashCard:(flashCard:IFlashCard,sentences:ISentence[],callBack:Function)=>AnyAction
    setSentencesAction:(sentences:ISentence[])=>AnyAction,
    deleteFlashCardAction:(flashCard:IFlashCard)=>AnyAction
}
interface IState {
    sentences:ISentence[]
    flashCard:IFlashCard
    editMode:boolean;
    error?:string;
    success?:string;
}
class AddFlashCard extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);
    }
    state:IState={
        sentences:this.props.sentences.sentences,
        editMode:false,
        flashCard:{title:'',level:'0',status:true}
    };
    componentWillMount() {
        this.checkAdmin();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("flashCard")){
            let flashCard=localState.flashCard;
            let data:ISentence[]=flashCard.sentences;
            for(let i=0;i<data.length;i++){
                data[i].index=i;
            }

            this.props.setSentencesAction(data);
            this.setState({flashCard:flashCard,editMode:true})
            return;
        }
    }
    componentDidUpdate(prevProps: Readonly<IProp>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.state.editMode&&this.props.sentences.sentences!=this.state.sentences){
            this.setState({sentences:this.props.sentences.sentences})
        }
        //alert(JSON.stringify(this.state.sentences))
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    onDragEnd(result:any) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if(this.state.sentences[result.source.index].en=='' && this.state.sentences[result.source.index].he=='')
            return;
        this.props.swap(result.source.index,result.destination.index)
       //alert(result.source.index+","+result.destination.index)
    }
    setTitle(text:any){
        let flashcard=this.state.flashCard;
        flashcard.title=text;
        this.setState({flashCard:flashcard})
    }
    setLevel(text:any){
        let flashcard=this.state.flashCard;
        flashcard.level=text;
        this.setState({flashCard:flashcard})
    }
    addFlashCard(){
        //alert(JSON.stringify(this.state.flashCard))
        //alert(JSON.stringify(this.state.flashCard));
        this.setState({error:''})
        if(this.state.flashCard.title==''){
            this.setState({error:'נא להכניס כותרת'})
            return;
        }
        if(this.state.flashCard.level=='0'){
            this.setState({error:'נא לבחור רמה'})
            return;
        }
        if(this.state.sentences.length==0 || (this.state.sentences.length==1 && this.state.sentences[0].en=='' )){
            this.setState({error:'נא להכניס משפטים'})
            return;
        }
        if(this.state.editMode){
            //alert(JSON.stringify(this.state.sentences))
            this.props.deleteFlashCardAction(this.state.flashCard)
           this.props.addFlashCard(this.state.flashCard,this.state.sentences,this.handleAddFlashCard.bind(this));
            return;
        }
        this.props.addFlashCard(this.state.flashCard,this.state.sentences,this.handleAddFlashCard.bind(this))
    }
    handleAddFlashCard(success:boolean,msg:string){
        if(success){
            this.setState({success:msg})
        }else{
            this.setState({error:msg})
        }
    }
    async onChange(e:any) {
        let context=this;
       /* readXlsxFile(e.target.files[0]).then((rows:string[][]) =>{
            //context.handleExcelFile.bind(context,rows);
            //context.setState({sentences:sentences})
        } )*/
        let rows=await readXlsxFile(e.target.files[0]);
        this.handleExcelFile(rows);

    }
    handleExcelFile(rows:string[][]){
        let sentences:ISentence[]=[];
        for(let i=0;i<rows.length;i++){
            let s:ISentence={index:i,en:rows[i][0],he:rows[i][1]};
            sentences.push(s);
        }
        this.setState({sentences:sentences})
    }
    render() {

        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195,marginBottom:30}}>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'כותרת'} value={this.state.flashCard.title} onChange={(event:any)=>this.setTitle(event.target.value)}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>

                                    <AppSelect options={levels} value={this.state.flashCard.level} onChange={(event:any)=>this.setLevel(event.target.value)}/>
                                </Col>
                            </Row>
                            <Row style={{marginTop:20,marginRight:5}}>
                                <input type={'file'} onChange={(event) => this.onChange(event)}/>
                            </Row>

                            <h4 style={{textAlign:'right',marginTop:20}}>משפטים</h4>
                            <DragDropContext  onDragEnd={this.onDragEnd.bind(this)}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps}
                                             ref={provided.innerRef}>
                                            {
                                                this.state.sentences.sort((a,b)=>{
                                                    if(a.index>b.index)
                                                        return 1;
                                                    if(a.index<b.index)
                                                        return -1;
                                                    return 0;
                                                }).map(s=>{
                                                    return <SentenceInput sentence={s}/>
                                                })
                                            }
                                        </div>
                                    )
                                    }

                                </Droppable>
                            </DragDropContext>
                            <Row style={{marginTop:10}}>
                                <AppButton loading={this.props.loading} text={this.state.editMode?'ערוך':'הוסף'} onClick={()=>this.addFlashCard()}/>
                            </Row>

                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddFlashCard));
/*

{
                                        this.state.sentences.map(s=>{
                                            return <SentenceInput sentence={s}/>
                                        })
                                    }
 */
