//import React from 'react';
import React  from 'react'
import { Router, Route, Switch } from 'dva/router';
import UsersPage from './pages/users'
import IndexPage from './pages/IndexPage';
import AddUser from "./pages/users/AddUser";
import SessionsPage from './pages/sessions'
import {saveHistoryAction} from "./models/app/AppActions";
import {createBrowserHistory} from 'history'
import AddSession from "./pages/sessions/AddSession";
import FlashCards from './pages/flashcards'
import AddFlashCard from "./pages/flashcards/AddFlashCard";
import GroupsPage from './pages/groups'
import AddGroup from "./pages/groups/AddGroup";
import Questions from './pages/questions'
import UserSessions from "./pages/users/UserSessions";
import GroupSessions from "./pages/groups/GroupSessions";
import PushNotificationsPage from './pages/pushnotifications'
import LessonsCalender from './pages/lessonCalender'
import PushNotificationsUsersPage from "./pages/pushnotifications/PushNotificationsUsersPage";
function RouterConfig({ history }:any) {
    history=createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
          <Route path="/"  exact component={IndexPage} />
          <Route path="/users"  exact component={UsersPage} />
          <Route path="/users/adduser"  exact component={AddUser} />
          <Route path="/users/sessions"  exact component={UserSessions} />
          <Route path="/sessions"  exact component={SessionsPage} />
          <Route path="/sessions/addsession"  exact component={AddSession} />
          <Route path="/flashcard"  exact component={FlashCards} />
          <Route path="/flashcard/addflashcard"  exact component={AddFlashCard} />
          <Route path="/groups"  exact component={GroupsPage} />
          <Route path="/groups/addgroup" exact component={AddGroup}/>
          <Route path="/groups/sessions" exact component={GroupSessions}/>
          <Route path="/questions" exact component={Questions}/>
          <Route path="/pushnotifications" exact component={PushNotificationsPage}/>
          <Route path="/pushnotifications/PushNotificationsUsersPage" exact component={PushNotificationsUsersPage}/>
          <Route path="/lessonCalender" exact component={LessonsCalender}/>
      </Switch>
    </Router>
  );
}

export default RouterConfig;
