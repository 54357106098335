import {IAppState} from "../app/AppState";
import UserState from "./UserState";
import UserServices from "./UserServices";
import IUser from "./IUser";
import IUserRegisteredSession from "../UserRegisteredSession/IUserRegisteredSession";


interface IAppModel{
  namespace:string
  state:UserState
  reducers:any
  effects:any
  subscriptions:any
}
//const users = ;
const UserModel:IAppModel={
  namespace: 'user',
  state: {
    currentUserSessions:[],
    users:[],
    fetching:false
  },
  reducers: {
    updateState(state:any, { payload }:any) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetch({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res=yield call(UserServices.fetchUsers);
        let users:IUser[]=res.data;
        yield put({
          type: 'updateState',
          payload: {
            users: users
          },
        });
      }catch (e) {

      }finally {


        yield put({type: 'updateState', payload: {fetching: false}});
      }

    },

    *fetchInsertUser({payload}:any,{put}:any){

      let userjson= localStorage.getItem('insertedUser');
      let user=null;
      if(userjson!=null){
        user=JSON.parse(userjson);
      }
      yield put({type: 'updateState', payload: {insertedUser:user}});
      payload.callBack(user);
    },
    *saveInsertedUser({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {insertedUser: payload.user}});
      localStorage.setItem('insertedUser',JSON.stringify(payload.user))
    },
    *fetchUserSessions({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res=yield call(UserServices.fetchUserRegisteredSessions,payload.userId);
        let users:IUserRegisteredSession[]=res.data;
        yield put({type: 'updateState', payload: { currentUserSessions: users},});
      }catch (e) {

      }finally {


        yield put({type: 'updateState', payload: {fetching: false}});
      }

    },
    *registerToSession({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res=yield call(UserServices.registerToSession,payload.user,payload.session);
      }catch (e) {
        if(e.hasOwnProperty('data')){
          payload.callBack(false,e.data);
        }
      }finally {


        yield put({type: 'updateState', payload: {fetching: false}});
      }
    },
    *deleteRegisteredSession({payload}:any,{call,put,select}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res = yield call(UserServices.deleteRegisteredSession,payload.session);
        let sessions:IUserRegisteredSession[]=yield select((state:IAppState) => state.user.currentUserSessions);
        sessions=sessions.filter(u=>u.id!=payload.session.id)
        yield put({type: 'updateState', payload: {currentUserSessions: sessions}});
      }catch (e) {

      }finally {


        yield put({type: 'updateState', payload: {fetching: false}});
      }
    },
    *addUser({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res=yield call(UserServices.addUser,payload.user);
        yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
        localStorage.removeItem('insertedUser')
        payload.callBack(true,"פעולה בוצעה בהצלחה")
      }catch (e) {
        payload.callBack(false,e.data);
      }finally {
        yield put({type: 'updateState', payload: {fetching: false}});

      }

    },
    *updateUserAction({payload}:any,{call,put}:any){
      yield put({type: 'updateState', payload: {fetching: true}});
      try{
        let res=yield call(UserServices.updateUser,payload.user);
        yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
        payload.callBack(true,"פעולה בוצעה בהצלחה")
      }catch (e) {
        payload.callBack(false,e.data);
      }finally {
        yield put({type: 'updateState', payload: {fetching: false}});

      }
    },
    *deleteUser({payload}:any,{call,put,select}:any){
      try{
        let res=yield call(UserServices.deleteUser,payload.user);
        let users:IUser[]=yield select((state:IAppState) => state.user.users);
        users=users.filter(u=>u.id!=payload.user.id)
        yield put({type: 'updateState', payload: {users: users}});
      }catch (e) {

      }

    },
    *disActiveUser({payload}:any,{call,put,select}:any){
      try{
        let res=yield call(UserServices.disActiveUser,payload.user);
        let users:IUser[]=yield select((state:IAppState) => state.user.users);
        payload.user.status=!payload.user.status;
        users=users.filter(u=>u.id!=payload.user.id)
        users.push(payload.user)
        yield put({type: 'updateState', payload: {users: users}});
      }catch (e) {

      }
    },
    *deleteUsers({payload}:any,{call,put,select}:any){
       let usersToDelete=payload.usersId;
       try{
         let res=yield call(UserServices.deleteUsers,usersToDelete);
         let users:IUser[]=yield select((state:IAppState) => state.user.users);
          for(let i=0;i<usersToDelete.length;i++){
            users=users.filter(u=>u.id!=usersToDelete[i]);
          }
         yield put({type: 'updateState', payload: {users: users}});
       }catch (e) {
          alert(JSON.stringify(e))
       }
    }
  },
  subscriptions: {
    setup({ dispatch }:any) {
      dispatch({ type: 'loadStorage' });
    },
  },
}
export default UserModel

