import React, {Component, CSSProperties, MouseEventHandler} from 'react';
import  './styles.css'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {Button} from "antd";
import {Link } from 'dva/router';
interface IState {

}
interface IProp {
    placeholder?:string
    type?:string
    onChange?:any
    value?:any
    errorMode?:boolean;
}

export default class AppInput extends Component<IProp & IState>{
    state={
        val:''
    }
    onChange(event:any){
        this.setState({val:event.target.value})
        this.props.onChange(event)
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

            <div className={'inputWrap'} >
                <p style={{margin:0,height:10,textAlign:'right'}}>{this.props.placeholder}</p>
               <input  type={this.props.type?this.props.type:'text'} value={this.props.value?this.props.value:this.state.val}  className={this.props.errorMode==true?'appErrorInput appInput':'appInput'} onChange={event => this.onChange(event)}/>
            </div>
        )
    }

}
