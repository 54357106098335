import React, {Attributes, Component, ReactPropTypes} from 'react';
import {Button} from "react-bootstrap";
import {ReactMic, ReactMicStopEvent} from "react-mic";
import {Col,Row} from "react-flexbox-grid";
import ReactPlayer from 'react-player'
// @ts-ignore
import ReactRecord from 'react-record';
import ISentence from "../../models/sentences/ISentence";

interface IState {
    record:boolean,
    pause:boolean
    seconds:number,
    mins:number;
    start:number;
    timeStr:string,
    timer:any,
    recordedBlob:any;
    playing:boolean
    key:string;
    showRecorder:boolean;
    blobEn:any;
    blobHe:any;
}
interface IProp {
    callBack:any
    recorderKey:string
    sentence:ISentence

}
export default class VoiceRecorder extends Component<IProp , IState>{

    constructor(props:IProp) {

        super(props);
        this.state={
            record:false,
            showRecorder:false,
            pause:false,
            seconds:0,
            mins:0,
            start:0,
            timeStr:'00:00',
            timer:null,
            recordedBlob:null,
            playing:false,
            key:props.recorderKey,
            blobEn:null,
            blobHe:null
        }

    }
    componentDidMount(): void {

    }

    startRecord(){
        if(this.state.record==true){

            clearInterval(this.state.timer)
            this.setState({record:false,pause:true});
            return;
        }
        this.setState({showRecorder:true,record:true,seconds:0,start:0,mins:0});

    }
    onSave(blobObject:any,key:string) {
        console.log("You can tap into the onSave callback", blobObject);
        if(this.props.recorderKey=='en'){
            this.setState({blobEn:blobObject.blobURL})
        }else{
            this.setState({blobHe:blobObject.blobURL})
        }
        var reader = new FileReader();
        reader.readAsDataURL(blobObject.blob);
        const props=this.props;
        reader.onloadend = function() {
            var base64data = reader.result;
            props.callBack(key,base64data)
        }.bind(this);

    };
    sendBase64(){

    }
    onData(recordedBlob:any) {
        //console.log(this.props.key+' chunk of real-time data is: ', recordedBlob);
    }
    onStop(recordedBlob: ReactMicStopEvent,key:string) {
        console.log('recordedBlob is: ', recordedBlob);
        //this.setState({recordedBlob:recordedBlob.blobURL});
        //this.setState({showRecorder:false});
        /*if(this.props.recorderKey=='en'){
            this.setState({blobEn:recordedBlob.blobURL})
        }else{
            this.setState({blobHe:recordedBlob.blobURL})
        }*/

    }
    getSrc(){
        if(this.props.recorderKey=='en'){
            return  this.state.blobEn;
        }else{
            return  this.state.blobHe;
        }
        return '';
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div style={{width:'100%'}} key={this.props.recorderKey}>

                <Row center={'lg'} style={{height:50}} >
                    {/*this.state.showRecorder&&<ReactMic
                        record={this.state.record}
                        key={this.props.recorderKey}// defaults -> false.  Set to true to begin recording
                        // defaults -> false.  Available in React-Mic-Gold/Plus upgrade only
                        visualSetting="frequencyBars" // defaults -> "sinewave".  Other option is "frequencyBars"
                        className={'recorder'}       // provide css class name
                        onStop={(recordedBlob)=>this.onStop(recordedBlob,this.state.key)}
                        onData={this.onData.bind(this)}// required - called when audio stops recording
                        strokeColor={'#B6D91F'}     // sinewave or frequency bar color
                        backgroundColor={'#ececec'} // background color
                        // mimeType="audio/mp3"     // defaults -> "audio/mp3".  Set to "audio/wav" for WAV audio format.  Available in React-Mic-Gold/Plus only.
                    />*/}
                    {
                        this.state.showRecorder&&<ReactRecord
                            record={this.state.record}
                            key={this.props.recorderKey}// defaults -> false.  Set to true to begin recording
                            className={'recorder'}
                            mimeType={'audio/wav'}
                            onSave={(blobObject:any)=>this.onSave(blobObject,this.state.key)}// provide css class name
                            onStop={(blobObject:any)=>this.onStop(blobObject,this.state.key)}        // callback to execute when audio stops recording
                            onData={(recordedBlob:any)=>this.onData(recordedBlob)}        // callback to execute when chunk of audio data is available
                        >
                            {
                                this.state.showRecorder&&<audio
                                    ref={c => {
                                        // this.audioSource = c;
                                    }}
                                    key={this.props.recorderKey}
                                    controls={true}
                                    src={this.getSrc()}
                                >
                                    <track kind="captions" />
                                </audio>
                            }

                        </ReactRecord>
                    }

                </Row>
                <Row style={{marginTop:10}} center={'lg'}>
                    <Button onClick={()=>this.startRecord()} style={{width:35,height:35,borderRadius:30}}>
                        <Row>
                            <img src={this.state.record?require('../../assets/pause-3.png'):require('../../assets/play-4.png')} style={{margin:'auto',width:'90%',height:'90%',marginLeft:!this.state.record?5:1}}/>
                        </Row>
                    </Button>

                </Row>
            </div>
        )
    }
}
