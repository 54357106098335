import {Dispatch} from "redux";

export function loginAction(userName:string,password:string,callBack:Function){
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'admin/login',
            payload:{
                userName:userName,
                password:password,
                callBack:callBack
            }
        });
    };
}
