import React, {Component, CSSProperties, MouseEventHandler} from 'react';
import  './styles.css'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {Button} from "antd";
import {Link } from 'dva/router';
import IKeyValue from "../../models/keyvalue/IKeyValue";
interface IState {

}
interface IProp {
    placeholder?:string
    type?:string;
    options?:IKeyValue[],
    onChange?:any
    value?:number | string;
    errorMode?:boolean;
}

export default class AppSelect extends Component<IProp & IState>{
    componentDidMount(): void {

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const options=this.props.options;
        return (

            <div className={'inputWrap'}>
                <p style={{margin:0,height:10,textAlign:'right'}}>{this.props.placeholder}</p>
                <select value={this.props.value?this.props.value:-1} className={this.props.errorMode==true?'appErrorInput appInput':'appInput'} placeholder={this.props.placeholder} onChange={event => this.props.onChange(event)}>
                    <option value={-1}>{this.props.placeholder}</option>
                    {

                        options && options.map((o:IKeyValue)=>{
                            return(
                                <option value={o.key}>{o.value}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }

}
