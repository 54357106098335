import IFlashCard from "../flashcard/IFlashCard";
import ISentence from "../sentences/ISentence";
import HttpRequest from "../../utils/HttpRequest";
import {ISession} from "./ISession";
function fetchSessions() {
    return HttpRequest('sessions',"GET");
}

function addSession(session:ISession,users:[],cards:[]) {
    return HttpRequest('sessions',"POST",{session:session,users:users,cards:cards});
}
function deleteSession(session:ISession) {
    return HttpRequest('sessions',"DELETE",{session:session});
}
function disActiveSession(session:ISession) {
    return HttpRequest('sessions/disActive',"POST",{session:session});
}
export default {addSession,fetchSessions,deleteSession,disActiveSession}
