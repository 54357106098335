import {Dispatch} from "redux";
import ISentence from "./ISentence";


export function setEnglishTextAction(text:string,index:number) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/setEnglishText',
            payload:{
                text:text,
                index:index
            }
        });
    };

}
export function setEnglishVoiceAction(voice:any,index:number) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/setEnglishVoice',
            payload:{
                voice:voice,
                index:index
            }
        });
    };

}
export function setHebrewVoiceAction(voice:any,index:number) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/setHebrewVoice',
            payload:{
                voice:voice,
                index:index
            }
        });
    };

}
export function setHebrewTextAction(text:string,index:number) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/setHebrewText',
            payload:{
                text:text,
                index:index
            }
        });
    };
}
export function swapAction(source:number,dest:number) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/swap',
            payload:{
                source:source,
                dest:dest
            }
        });
    };
}
export function setSentencesAction(sentences:ISentence[]) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'sentences/set',
            payload:{
                sentences:sentences,
            }
        });
    };
}
