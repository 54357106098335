



import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import SessionState from "./SessionState";
import FlashCardServices from "../flashcard/FlashCardServices";
import SessionServices from "./SessionServices";
import IFlashCard from "../flashcard/IFlashCard";
import {ISession} from "./ISession";


interface IAppModel{
    namespace:string
    state:SessionState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const SessionModel:IAppModel={
    namespace: 'session',
    state: {
        sessions:[],
        loading:false
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(SessionServices.fetchSessions);
                yield put({type: 'updateState', payload: {sessions: res.data}});
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {loading: false}});

            }

        },
        *add({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(SessionServices.addSession,payload.session,payload.users,payload.cards);
                yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
                payload.callBack(true,"פעולה בוצעה בהצלחה")
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {loading: false}});

            }

        },
        *deleteSession({payload}:any,{call,put,select}:any){
            yield call(SessionServices.deleteSession,payload.session);
            let sessions:ISession[]=yield select((state:IAppState) => state.session.sessions);
            sessions=sessions.filter(s=>s.id!=payload.session.id)
            yield put({type: 'updateState', payload: {sessions: sessions}});
        },
        *disActiveSession({payload}:any,{call,put,select}:any){
            yield call(SessionServices.disActiveSession,payload.session);
            let sessions:ISession[]=yield select((state:IAppState) => state.session.sessions);
            payload.session.status=!payload.session.status;
            sessions=sessions.filter(s=>s.id!=payload.session.id)
            sessions.push(payload.session)
            yield put({type: 'updateState', payload: {sessions: sessions}});
        },
    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default SessionModel
