import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Checkbox, Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {IAppState} from "../../models/app/AppState";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {AnyAction} from "redux";
import {deleteSessionAction, disActiveSessionAction, fetchSessionsAction} from "../../models/session/SessionActions";
import {ISession} from "../../models/session/ISession";
import IGroup from "../../models/group/IGroup";
import {
    deleteGroupAction,
    deleteGroupsAction,
    disActiveGroupAction,
    fetchGroupsAction
} from "../../models/group/GroupActions";
import IUser from "../../models/user/IUser";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import  '../../index.css'
import {
    deleteLessonAction,
    fetchGroupsByLessonDate,
    replaceLessonAction
} from "../../models/UserRegisteredSession/UserRegisteredSessionActions";
import Modal from "antd/es/modal";
function mapStateToProps(state:IAppState) {
    return {
        loading:state.session.loading,
        groups:state.group.groups,
    };
}

const mapDispatchToProps = {
    fetchGroupsAction:fetchGroupsAction,
    deleteGroupAction:deleteGroupAction,
    disActiveGroupAction:disActiveGroupAction,
    deleteGroupsAction:deleteGroupsAction,
    deleteLessonAction:deleteLessonAction,
    replaceLessonAction:replaceLessonAction,
    fetchGroupsByLessonDate:fetchGroupsByLessonDate
};
interface IProp {
    groups:IGroup[],
    loading:boolean,
    fetchGroupsAction:()=>AnyAction
    deleteGroupAction:(group:IGroup)=>AnyAction
    disActiveGroupAction:(group:IGroup)=>AnyAction
    deleteGroupsAction:(users:[])=>AnyAction
    deleteLessonAction:(groupId:number,date:string,sendPush:boolean,pushText:string,callBack:Function)=>AnyAction;
    replaceLessonAction:(groupId:number,date:string,newDate:string,sendPush:boolean,pushText:string,callBack:Function)=>AnyAction;
    fetchGroupsByLessonDate:(date:string,callBack:Function)=>AnyAction;
}
interface IState {
    groups:IGroup[]
    selected:[]
    q:string
    date:Date;
    showDeleteModal:boolean,
    showReplaceModal:boolean,
    lessonToDeleteGroupId:number,
    replaceDate:string;
    sendPush: boolean;
    pushText:string,
    error?:string;
    success?:string;
}
class LessonsCalender extends Component<IProp&RouteComponentProps&IState> {
    constructor(props:any) {
        super(props);

    }
    state:IState = {
        groups:[],
        selected:[],
        q:'',
        date:new Date(),
        replaceDate:'',
        showDeleteModal:false,
        showReplaceModal:false,
        lessonToDeleteGroupId:-1,
        sendPush:false,
        pushText:''
    };
    componentDidMount(): void {
        this.checkAdmin();
        let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
        this.props.fetchGroupsByLessonDate(date,this.handleGroups.bind(this));
    }
    handleGroups(groups:IGroup[]){
        this.setState({groups:groups});
    }
    setDate(date:Date){
        if(date!=this.state.date){
            this.setState({date:date})
            let dateText=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
            this.props.fetchGroupsByLessonDate(dateText,this.handleGroups.bind(this));
        }

    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    showDeleteModal(groupId:number){
        let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
        let text='המפגש בתאריך '+date+" מבוטל "
        this.setState({error:'',success:'',showDeleteModal:true,lessonToDeleteGroupId:groupId,pushText:text})
    }
    deleteLesson(){
        let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
        //antd.Modal.confirm()
        this.setState({showDeleteModal:true})
        this.props.deleteLessonAction(this.state.lessonToDeleteGroupId,date,this.state.sendPush,this.state.pushText,this.handleDeleteLesson.bind(this))
        //alert(groupId+","+date)
    }
    handleDeleteLesson(success:boolean,text:string){
        if(success) {
            this.setState({success:text})
            return;
        }
        this.setState({error:text})
    }
    showReplaceModal(groupId:number){
        let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
        this.setState({error:'',success:'',showReplaceModal:true,lessonToDeleteGroupId:groupId,pushText:''})
    }
    replaceLesson(){
        if(this.state.replaceDate==''){
            this.setState({error:'נא לבחור תאריך'});
            return;
        }
        let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
        this.props.replaceLessonAction(this.state.lessonToDeleteGroupId,date,this.state.replaceDate,this.state.sendPush,this.state.pushText,this.handleDeleteLesson.bind(this))

    }
    render() {


        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <p style={{fontSize:20,textAlign:'center'}}>{this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate()}</p>
                            <Calendar
                                onChange={(date )=>{
                                    if(date instanceof  Date)
                                        this.setDate(date)
                                }}
                                value={this.state.date}
                                className={'react-calendar'}
                                calendarType={"Hebrew"}
                            />
                        </div>
                        <div style={{marginTop:40}}>
                            {
                                this.state.groups.map((g)=>{
                                    let founded=true;

                                    if(founded){
                                        return (<div style={{width:350,height:100,display:'flex',backgroundColor:'#0073BF',marginBottom:10,marginLeft:"auto",marginRight:'auto'}}>
                                            <div style={{flex:0.5,alignItems:'flex-start',justifyContent:'center'}}>
                                                <p style={{color:'#fff',fontSize:19,marginTop:11,float:'right',marginRight:15}}>{g.name}</p>
                                            </div>
                                            <div style={{flex:0.5,paddingTop:13,paddingLeft:10,alignItems:'center',justifyContent:'center'}}>
                                                <AppButton color={'red'} text={'מחק מפגש'} onClick={()=>this.showDeleteModal(g.id?g.id:-1)}></AppButton>
                                                <div style={{marginTop:5}}>
                                                    <AppButton  text={'החלף מועד'} onClick={()=>this.showReplaceModal(g.id?g.id:-1)}></AppButton>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                })
                            }
                        </div>

                    </Col>
                </Grid>
                {/*  delete modal */}
                <Modal
                    onCancel={()=>this.setState({showDeleteModal:false})}
                    onOk={()=>this.deleteLesson()}
                    okText={'מחק'}
                    cancelText={'ביטול'}
                    visible={this.state.showDeleteModal}>
                    <div style={{marginTop:30}}>
                        <Row >
                            <Checkbox style={{fontSize:18}}  checked={this.state.sendPush}  onChange={e => this.setState({sendPush:e.target.checked})}>שלח הודעה לנרשמים</Checkbox>

                        </Row>

                        <Row hidden={!this.state.sendPush}>
                            <AppInput placeholder={'הודעה'} value={this.state.pushText} onChange={(event:any)=>this.setState({pushText:event.target.value})} />
                        </Row>
                        <Row hidden={!this.state.error}>
                            <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                        </Row>
                        <Row hidden={!this.state.success}>
                            <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                        </Row>
                    </div>
                </Modal>
                {/*  replace modal */}
                <Modal
                    onCancel={()=>this.setState({showReplaceModal:false})}
                    onOk={()=>this.replaceLesson()}
                    okText={'אישור'}
                    cancelText={'ביטול'}
                    visible={this.state.showReplaceModal}>
                    <div style={{marginTop:30}}>
                        <Row>
                            <AppInput placeholder={'תאריך'} type={'date'} onChange={(event:any)=>{

                                let date=this.state.date.getFullYear()+"-"+(this.state.date.getMonth()+1)+"-"+this.state.date.getDate();
                                let text='המפגש בתאריך '+date+" הועבר לתאריך "+event.target.value;
                                this.setState({replaceDate:event.target.value,pushText:text});
                            }}/>
                        </Row>
                        <Row >
                            <Checkbox style={{fontSize:18}}  checked={this.state.sendPush}  onChange={e => this.setState({sendPush:e.target.checked})}>שלח הודעה לנרשמים</Checkbox>

                        </Row>

                        <Row hidden={!this.state.sendPush}>
                            <AppInput placeholder={'הודעה'} value={this.state.pushText} onChange={(event:any)=>this.setState({pushText:event.target.value})} />
                        </Row>
                        <Row hidden={!this.state.error}>
                            <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                        </Row>
                        <Row hidden={!this.state.success}>
                            <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                        </Row>
                    </div>
                </Modal>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LessonsCalender));
