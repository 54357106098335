import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {AnyAction} from "redux";
import {createBrowserHistory} from "history";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import UsersTable from "../../components/usersTable";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import FlashCardsTable from "../../components/flashCardsTable";
import IFlashCard from "../../models/flashcard/IFlashCard";
function mapStateToProps(state:IAppState) {
    return {
        flashCards:state.flashcard.flashCards,
        loading:state.flashcard.loading
    };
}

const mapDispatchToProps = {
    fetchFlashCards:fetchFlashCardsAction
};
interface IProp {
    flashCards:IFlashCard[]
    loading:boolean,
    fetchFlashCards:()=>AnyAction

}
class FlashCardsPage extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);
        this.state = {

        };

    }

    componentWillMount() {
       this.props.fetchFlashCards();
       this.checkAdmin();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    moveToAddFlashCard(){
        // let history=createBrowserHistory();
        // history.push();
        /// history.go(1);
        this.props.history.push('/flashcard/addflashcard');
    }
    render() {


        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <AppButton text={'הוסף כרטיסיה חדשה'} onClick={()=>this.moveToAddFlashCard()}/>
                            </Row>
                            <FlashCardsTable data={this.props.flashCards} loading={this.props.loading} />

                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FlashCardsPage));

