import {AnyAction, Dispatch} from "redux";


//export type saveHistoryAction = () => AnyAction;
export type saveHistoryActionType = () => AnyAction;
export function saveHistoryAction() {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'user/saveHistory',
        });
    };

}
