


import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import IFlashCard from "../flashcard/IFlashCard";
import QuestionState from "./QuestionState";
import IQuestion from "./IQuestion";
import QuestionServices from "./QuestionServices";
import {ISession} from "../session/ISession";


interface IAppModel{
    namespace:string
    state:QuestionState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const QuestionModel:IAppModel={
    namespace: 'question',
    state: {
        questions:[],
        loading:false
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){

            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(QuestionServices.fetchQuestions);
                let questions:IQuestion[]=res.data;
                yield put({type: 'updateState', payload: {questions:questions }});
            }catch (e) {

            }finally {
                yield put({type: 'updateState', payload: {loading: false}});
            }
        },
        *setAnswer({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(QuestionServices.setAnswer,payload.question);
               // let questions:IQuestion[]=res.data;
                //yield put({type: 'updateState', payload: {questions:questions }});
            }catch (e) {

            }finally {
                yield put({type: 'updateState', payload: {loading: false}});
            }
        },
        *deleteQuestion({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let question:IQuestion=payload.question;
                let res=yield call(QuestionServices.deleteQuestion,question);
                let questions:IQuestion[]=yield select((state:IAppState) => state.question.questions);
                questions=questions.filter(q=>q.id!=question.id)
                // let questions:IQuestion[]=res.data;
                yield put({type: 'updateState', payload: {questions:questions }});
            }catch (e) {

            }finally {
                yield put({type: 'updateState', payload: {loading: false}});
            }
        }


    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default QuestionModel
