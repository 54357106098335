import IFlashCard from "../flashcard/IFlashCard";
import ISentence from "../sentences/ISentence";
import HttpRequest from "../../utils/HttpRequest";

function deleteLesson(groupId:number,date:string,sendPush:boolean,pushText:string) {
    return HttpRequest('registeredsession',"DELETE",{groupId:groupId,date:date,sendPush:sendPush,pushText:pushText});
}
function replaceLesson(groupId:number,date:string,newDate:string,sendPush:boolean,pushText:string) {
    return HttpRequest('registeredsession',"PATCH",{groupId:groupId,date:date,newDate:newDate,sendPush:sendPush,pushText:pushText});
}
function fetchGroupsByLessonDate(date:string) {
    return HttpRequest('registeredsession?date='+date,"GET");
}
export default {deleteLesson,replaceLesson,fetchGroupsByLessonDate}
