
import SentencesState from "./SentencesState";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import ISentence from "./ISentence";


interface IAppModel{
    namespace:string
    state:SentencesState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const SentencesModel:IAppModel={
    namespace: 'sentences',
    state: {
        sentences:[{index:0,en:'',he:'',enVoice:null,heVoice:null}]
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *set({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {sentences: payload.sentences}});
        },
        *setEnglishText({payload}:any,{call,put,select}:any){
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            let index=payload.index;
            data[index].en=payload.text;
            if(data[index].he.length>1 && data[index].en.length>1 && data.length==index+1){
                data.push({index:index+1,en:'',he:'',enVoice:null,heVoice:null})
            }
            yield put({type: 'updateState', payload: {sentences: data}});
        },
        *setEnglishVoice({payload}:any,{call,put,select}:any){
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            let index=payload.index;
            data[index].enVoice=payload.voice;
            yield put({type: 'updateState', payload: {sentences: data}});
        },
        *setHebrewText({payload}:any,{call,put,select}:any){
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            let index=payload.index;
            data[index].he=payload.text;
            if(data[index].he.length>1 && data[index].en.length>1 && data.length==index+1){
                data.push({index:index+1,en:'',he:'',enVoice:null,heVoice:null})
            }
            yield put({type: 'updateState', payload: {sentences: data}});
        },
        *setHebrewVoice({payload}:any,{call,put,select}:any){
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            let index=payload.index;
            data[index].heVoice=payload.voice;
            yield put({type: 'updateState', payload: {sentences: data}});
        },
        *swap({payload}:any,{call,put,select}:any){
            let source=payload.source;
            let dest=payload.dest;
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            /*let tempData:ISentence[]=[];
            for(let i=0;i<data.length;i++){
                tempData.push(data[i]);
                if(dest==i){}
            }
            data[dest]=data[source];
            alert(JSON.stringify(data))*/
            //let temp=data[dest];
            //data[dest]=data[source];
           // alert(source+","+dest);
            let tempData:ISentence[]=[];
            let founded=false;
            for(let i=0;i<data.length;i++){

                if(i==dest && !founded){
                    founded=true;
                    tempData.push(data[source]);
                }
                if(i!=source){
                    tempData.push(data[i]);
                }

            }
            for(let i=0;i<tempData.length;i++){
                tempData[i].index=i;
            }
            yield put({type: 'updateState', payload: {sentences: tempData}});
        }
    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default SentencesModel

/*

 /*let source=payload.source;
            let dest=payload.dest;
            let data:ISentence[]=yield select((state:IAppState) => state.sentences.sentences);
            let src=data[source];
            let tempData:ISentence[]=data.filter(s=>s.index!=source);
            let newData:ISentence[]=[];
            let flag=0;
            for(let i=0;i<tempData.length;i++){

                if(dest==i){
                    flag=1;
                    newData.push(src)
                }
                if(flag==0){
                    newData.push(tempData[i]);
                }
                if(flag==1){
                     .push(tempData[i]);
                    newData[i].index++;
                }
            }
            alert(JSON.stringify(newData))
            yield put({type: 'updateState', payload: {sentences: newData}});*/
