import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Checkbox, Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import IUser from "../../models/user/IUser";
import {IAppState} from "../../models/app/AppState";
import {saveHistoryAction} from "../../models/app/AppActions";
import {
    addUserAction,
    fetchInsertedUserAction,
    fetchUsersAction,
    saveInsertedUserAction, updateUserAction
} from "../../models/user/UserActions";
import {RouteComponentProps, withRouter} from "react-router";
import {AnyAction} from "redux";
import {fetchGroupsAction} from "../../models/group/GroupActions";
import IGroup from "../../models/group/IGroup";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import moment, {Moment} from "moment";
import IUserRegisteredSession from "../../models/UserRegisteredSession/IUserRegisteredSession";
import ISessionDay from "../../models/ISessionDay";
function mapStateToProps(state:IAppState) {
    return {
        insertedUser:state.user.insertedUser,
        loading:state.user.fetching,
        groups:state.group.groups
    };
}

const mapDispatchToProps = {
    saveInsertedUser:saveInsertedUserAction,
    fetchInsertUser:fetchInsertedUserAction,
    addUser:addUserAction,
    updateUser:updateUserAction,
    fetchGroupsAction:fetchGroupsAction
};
interface IProp{
    insertedUser:IUser
    loading:boolean
    groups:IGroup[]
    addUser:(user:IUser,callBack:Function)=>AnyAction
    updateUser:(user:IUser,callBack:Function)=>AnyAction
    saveInsertedUser:(user:IUser)=>AnyAction
    fetchInsertUser:(callBack:Function)=>AnyAction
    fetchGroupsAction:()=>AnyAction
}
interface IFormError{
    personId:boolean;
    firstName:boolean;
    lastName:boolean;
    maxSessions:boolean;
    startingDate:boolean;
    group:boolean;
}
interface IState {
    errors:IFormError;
    user:IUser;
    editMode:boolean;
    error?:boolean;
    success?:boolean;
    appLang:string;
}
class AddUser extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);

    }
    state:IState={
        errors:{firstName:false,lastName:false,personId:false,maxSessions:false,group:false,startingDate:false},
        editMode:false,
        user:this.props.insertedUser,
        appLang:'en',
    }
    componentWillMount() {
        this.checkAdmin();
        this.props.fetchGroupsAction();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("user")){
            let user=localState.user;
            this.setState({user:user,editMode:true,appLang:user.appLang})
            return;
        }

        this.props.fetchInsertUser(this.handleInsertedUser.bind(this));
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    handleInsertedUser(user:IUser){
       this.setState({user:user})
    }
    setStartingDate(groupId:number){
        let group=this.props.groups.filter(g=>g.id==groupId)[0];
        let daysDates:IUserRegisteredSession[]=[];
        if(group.GroupSessionsDays.length==0)
            return;
        for(let i=0;i<group.GroupSessionsDays.length;i++){
            const today = moment().day(group.GroupSessionsDays[i].day-1);
            daysDates.push({date:today.toDate(),numOfSession:0})
        }
        daysDates=daysDates.sort((a:IUserRegisteredSession,b:IUserRegisteredSession)=>{
            let momA=moment(a.date);
            let momB=moment(b.date);
            if(momA.isBefore(momB))
                return -1;
            else if(momB.isBefore(momA))
                return 1;
            return 0

        })

        let user=this.state.user;
        let ll=user.maxSessions/daysDates.length;
        ll=parseInt(ll+"");
        let diff=user.maxSessions-ll*daysDates.length;
        for(let i=0;i<daysDates.length;i++){
            daysDates[i].numOfSession=ll;
        }
        daysDates[0].numOfSession=daysDates[0].numOfSession+diff;

        user.startingDate=moment(daysDates[0].date).format('YYYY-MM-DD');
        user.dayDates=daysDates;
        this.setState({user:user});
    }
     setDayDates(startDate:string){
        let user=this.state.user;
        let group=this.props.groups.filter(g=>g.id==user.groupId)[0];
        let daysDates:IUserRegisteredSession[]=[];
        for(let i=0;i<group.GroupSessionsDays.length;i++){
            let toDayMoment=moment(startDate,'YYYY-MM-DD');
            let startDay=toDayMoment.day();
            let today = null;
            if(group.GroupSessionsDays[i].day<startDay){
                today = toDayMoment.add(1,'week').subtract(startDay,'d').add(group.GroupSessionsDays[i].day,'d');
                daysDates.push({date:today.toDate(),numOfSession:0})
            }
            if(group.GroupSessionsDays[i].day>startDay){
                today = toDayMoment.add(group.GroupSessionsDays[i].day-startDay,'d');
                daysDates.push({date:today.toDate(),numOfSession:0})
            }
        }

        let ll=user.maxSessions/daysDates.length;
        ll=parseInt(ll+"");
        let diff=user.maxSessions-ll*daysDates.length;
        for(let i=0;i<daysDates.length;i++){
            daysDates[i].numOfSession=ll;
        }
        daysDates=daysDates.sort((a:IUserRegisteredSession,b:IUserRegisteredSession)=>{
            let momA=moment(a.date);
            let momB=moment(b.date);
            if(momA.isBefore(momB))
                return -1;
            else if(momB.isBefore(momA))
                return 1;
            return 0

        })
        daysDates[0].numOfSession=daysDates[0].numOfSession+diff;
        user.dayDates=daysDates;


    }
    setUserProp<K extends keyof IUser>(event:any,key:K){
        let user=this.state.user;
        if(user==null || user==undefined)
            user={personId:'',firstName:'',lastName:'',email:'',phone:'',userName:'',passWord:'',groupId:-1,maxSessions:0};
        if(key=="groupId"){
            this.setStartingDate(event.target.value)
            //alert(JSON.stringify(this.props.groups[2]))
        }
        user[key]=event.target.value;
        this.setState({user:user})
        this.props.saveInsertedUser(user);

    }
    addUser(){
        this.setState({error:null,success:null})
        let user=this.state.user;

        let errors={firstName:false,lastName:false,personId:false,maxSessions:false,group:false,startingDate:false}
        let error=false;
        if(user==null){
            errors={firstName:true,lastName:true,personId:true,maxSessions:true,group:true,startingDate:true}
            this.setState({errors:errors});
            return;
        }
        user.appLang=this.state.appLang;
        if(user.personId==null || user.personId==''){
            errors.personId=true;
            error=true;
        }
        if(user.firstName==null || user.firstName==''){
            errors.firstName=true;
            error=true;
        }
        if(user.lastName==null || user.lastName==''){
            errors.lastName=true;
            error=true;
        }
        if(user.maxSessions==null || user.maxSessions==0){
            errors.maxSessions=true;
            error=true;
        }
        if(user.groupId==null || user.groupId==-1){
            errors.group=true;
            error=true;
        }
        if(user.startingDate==null || user.startingDate==""){
            errors.startingDate=true;
            error=true;
        }
        if(user.startingDate!='' && user.groupId!=-1){
            let toDayMoment=moment(user.startingDate,'YYYY-MM-DD');
            let day=toDayMoment.day()+1;
            let group=this.props.groups.filter(g=>g.id==user.groupId)[0];
            let g_day=group.GroupSessionsDays.filter(d=>d.day==day);
            if(g_day==null || g_day.length==0){
                errors.startingDate=true;
                error=true;
                this.setState({error:'קבוצה זו לא לומדת בתאריך זה'})
            }

        }
        this.setState({errors:errors});
        if(error){

            return;
        }
        if(this.state.editMode){
            this.props.updateUser(this.state.user,this.handleAddUser.bind(this));
            return;
        }

        user.userName=user.personId;
        user.passWord=user.personId;
        user.isActive=false;
        user.status=true;
        if(user.startingDate)
            this.setDayDates(user.startingDate);
        this.props.addUser(this.state.user,this.handleAddUser.bind(this));
    }
    handleAddUser(success:boolean,msg:string){
        if(success){
            this.setState({success:msg})
        }else{
            this.setState({error:msg})
        }
    }
    render() {
        const options:IKeyValue[]=[];
        if(this.props.groups){
            this.props.groups.forEach((g:IGroup)=>options.push({key:g.id,value:g.name}))
        }
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'שם פרטי'} errorMode={this.state.errors.firstName} value={this.state.user?this.state.user.firstName:''} onChange={(event:any)=>this.setUserProp(event,"firstName")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'שם משפחה'} errorMode={this.state.errors.lastName} value={this.state.user?this.state.user.lastName:''} onChange={(event:any)=>this.setUserProp(event,"lastName")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'תעודת זהות'} errorMode={this.state.errors.personId} value={this.state.user?this.state.user.personId:''} onChange={(event:any)=>this.setUserProp(event,"personId")}/>
                                </Col>
                            </Row>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'מספר טלפון'} value={this.state.user?this.state.user.phone:''} onChange={(event:any)=>this.setUserProp(event,"phone")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'דואר אליקטרוני'} value={this.state.user?this.state.user.email:''} onChange={(event:any)=>this.setUserProp(event,"email")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'תאריך לידה'} type={'date'} value={this.state.user?this.state.user.birthDay:''} onChange={(event:any)=>this.setUserProp(event,"birthDay")}/>
                                </Col>
                            </Row>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'כתובת'}  value={this.state.user?this.state.user.address:''} onChange={(event:any)=>this.setUserProp(event,"address")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>

                                    <AppSelect options={options} placeholder={'קבוצה'} errorMode={this.state.errors.group} value={this.state.user?this.state.user.groupId:-1} onChange={(event:any)=>this.setUserProp(event,"groupId")}/>
                                </Col>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'מספר מפגשים'} errorMode={this.state.errors.maxSessions} value={this.state.user?this.state.user.maxSessions:''} onChange={(event:any)=>this.setUserProp(event,"maxSessions")}/>
                                </Col>

                            </Row>
                            <Row style={{width:'100%'}}>
                                <Col lg={4} md={4} sm={6}>
                                    <AppInput placeholder={'תאריך התחלה'}  errorMode={this.state.errors.startingDate} value={this.state.user?this.state.user.startingDate:''} type={'date'} onChange={(event:any)=>this.setUserProp(event,"startingDate")}/>
                                </Col>
                            </Row>
                            <Row style={{width:'100%',paddingRight:10,marginTop:15}}>
                                <p style={{width:'100%',textAlign:'right',margin:0}}>שפת ממשק</p>
                                <Checkbox style={{fontSize:18}} checked={this.state.appLang=='en'}  onChange={e => this.setState({appLang:e.target.checked?'en':'he'})} >אנגלית</Checkbox>
                            </Row>
                            <Row style={{width:'100%',paddingRight:10}}>
                                <Checkbox style={{fontSize:18}} checked={this.state.appLang=='he'}  onChange={e => this.setState({appLang:e.target.checked?'he':'en'})} >עברית</Checkbox>
                            </Row>
                            <Row style={{marginTop:20,paddingRight:10}}>
                                <AppButton  loading={this.props.loading} width={100} text={this.state.editMode?'ערוך':'הוסף'} onClick={()=>this.addUser()}/>
                            </Row>
                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddUser));
