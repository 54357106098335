import IFlashCard from "../flashcard/IFlashCard";
import ISentence from "../sentences/ISentence";
import HttpRequest from "../../utils/HttpRequest";
import IGroup from "./IGroup";
import ISessionDay from "../ISessionDay";

function fetchGroups(flashcard:IFlashCard,sentences:ISentence[]) {
    return HttpRequest('groups',"GET");
}
function fetchGroupUsers(groupId:number) {
    return HttpRequest('groups/users?id='+groupId,"GET");
}
function addGroup(group:IGroup,days:ISessionDay[]) {
    return HttpRequest('groups',"POST",{group:group,days:days});
}
function updateGroup(group:IGroup,days:ISessionDay[]) {
    return HttpRequest('groups',"PATCH",{group:group,days:days});
}
function deleteGroup(group:IGroup,days:ISessionDay[]) {
    return HttpRequest('groups',"DELETE",{group:group});
}
function disActiveGroup(group:IGroup) {
    return HttpRequest('groups/disActive',"PATCH",{group:group});
}
function deleteGroups(ids:[]) {
    return HttpRequest('groups/multiDelete',"DELETE",{ids:ids});
}
function fetchGroupSessions(id:number) {
    return HttpRequest('groups/sessions?id='+id,"GET",);
}
export default {fetchGroups,fetchGroupUsers,addGroup,updateGroup,deleteGroup,disActiveGroup,deleteGroups,fetchGroupSessions}
