import React, { Component } from 'react';
import  './styles.css'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {Button} from "antd";
import {Link } from 'dva/router';
interface IState {

}
export default class Index extends Component<IState>{


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

                <Col xs={12} sm={3} md={3} lg={2}  id='headerWrap'>
                    <img src={require('../../assets/logo.png')} id='headerLogo'/>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'usersButton'}  className={'headerButton'} to="/users">
                               <h1 className={'headerButtonText'}>משתמשים</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'sessionsButton'} className={'headerButton'} to="/sessions">
                            <h1 className={'headerButtonText'}>מפגשים</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'flashCardsButton'} className={'headerButton'} to="/flashcard">
                            <h1 className={'headerButtonText'}>כרטסיות</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'groupsButton'} className={'headerButton'} to="/groups">
                            <h1 className={'headerButtonText'}>קבוצות</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'calButton'} className={'headerButton'} to="/lessonCalender">
                            <h1 className={'headerButtonText'}>יומן מפגשים</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'qestButton'} className={'headerButton'} to="/questions">
                            <h1 className={'headerButtonText'}>שאלות מתלמדים</h1>
                        </Link>
                    </Row>
                    <Row className={'headerButtonWrap'} >
                        <Link id={'notfiButton'} className={'headerButton'} to="/pushnotifications">
                            <h1 className={'headerButtonText'}>הודעות מערכת</h1>
                        </Link>
                    </Row>

                    <Row className={'headerButtonWrap'} >
                        <Link id={'logoutButton'} className={'headerButton'} to="/">
                            <h1 className={'headerButtonText'}>יציאה</h1>
                        </Link>
                    </Row>
                </Col>
        )
    }

}
