import {Dispatch} from "redux";
import IGroup from "./IGroup";
import ISessionDay from "../ISessionDay";

export function fetchGroupsAction() {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/fetch',
        });
    };

}
export function fetchGroupUsersAction(groupId:number,callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/fetchGroupUsers',
            payload:{
                groupId:groupId,
                callBack:callBack
            }
        });
    };

}
export function addGroupAction(group:IGroup,days:ISessionDay[],callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/addGroup',
            payload:{
                group:group,
                days:days,
                callBack:callBack
            }
        });
    };

}

export function updateGroupAction(group:IGroup,days:ISessionDay[],callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/updateGroup',
            payload:{
                group:group,
                days:days,
                callBack:callBack
            }
        });
    };

}
export function deleteGroupAction(group:IGroup) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/deleteGroup',
            payload:{
                group:group,
            }
        });
    };

}
export function disActiveGroupAction(group:IGroup) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'group/disActiveGroup',
            payload:{
                group:group,
            }
        });
    };

}
export function deleteGroupsAction(groupsId:[]) {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'group/deleteGroups',
            payload:{
                groupsId:groupsId,
            }
        });
    };

}
export function fetchGroupSessionsAction(id:number){
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'group/fetchGroupSessions',
            payload:{
                id:id,
            }
        });
    };
}
