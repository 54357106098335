import React, {Component, CSSProperties, MouseEventHandler} from 'react';
import  './styles.css'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {Button, Progress, Spin} from "antd";
import {Link } from 'dva/router';
interface IState {

}
interface IProp {
    text:string;
    width?:number;
    color?:string;
    onClick:MouseEventHandler;
    loading?:boolean
}

export default class AppButton extends Component<IProp & IState>{

    getStyles():CSSProperties{
        let style:CSSProperties={};
        if(this.props.width)
            style.width=this.props.width;
        if(this.props.color)
            style.backgroundColor=this.props.color;
        return style;
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

            <div>

                    <button className='appButton' style={this.getStyles()} onClick={!this.props.loading?this.props.onClick:()=>null}>
                        <Row hidden={this.props.loading} center={'lg'}>
                            <p className='appButtonText'>{this.props.text}</p>
                        </Row>

                        <Row hidden={!this.props.loading} center={'lg'}>
                            <Spin style={{marginTop:5}}/>
                        </Row>
                    </button>

            </div>
        )
    }

}
