
import HttpRequest from "../../utils/HttpRequest";
import IQuestion from "./IQuestion";

function fetchQuestions() {
    return HttpRequest('questions',"GET");
}
function setAnswer(question:IQuestion) {
    return HttpRequest('questions',"PATCH",{question:question});
}
function deleteQuestion(question:IQuestion) {
    return HttpRequest('questions',"DELETE",{question:question});
}
export default {fetchQuestions,setAnswer,deleteQuestion}
