import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
//import styles from './IndexPage.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import AppButton from "../../components/appButton";
import 'antd/dist/antd.css';
import {Table} from "antd";
import AppInput from "../../components/appInput";
import AppSelect from "../../components/appSelect";
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {IAppState} from "../../models/app/AppState";
import {fetchFlashCardsAction} from "../../models/flashcard/FlashCardActions";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {AnyAction} from "redux";
import {deleteSessionAction, disActiveSessionAction, fetchSessionsAction} from "../../models/session/SessionActions";
import {ISession} from "../../models/session/ISession";
import IGroup from "../../models/group/IGroup";
import {
    deleteGroupAction,
    deleteGroupsAction,
    disActiveGroupAction,
    fetchGroupsAction
} from "../../models/group/GroupActions";
import IUser from "../../models/user/IUser";
import {
    deleteQuestionAction,
    fetchQuestionAction,
    setQuestionAnswerAction
} from "../../models/question/QuestionActions";
import Circle from "antd/es/progress/Circle";


// @ts-ignore
import ActivityIndicator from 'react-activity-indicator'
import IQuestion from "../../models/question/IQuestion";

function mapStateToProps(state:IAppState) {
    return {
        loading:state.question.loading,
        questions:state.question.questions,
    };
}

const mapDispatchToProps = {
    fetchQuestionAction:fetchQuestionAction,
    setQuestionAnswerAction:setQuestionAnswerAction,
    deleteQuestionAction:deleteQuestionAction
};
interface IProp {
    questions:IQuestion[]
    loading:boolean;
    fetchQuestionAction:()=>AnyAction;
    setQuestionAnswerAction:(question:IQuestion)=>AnyAction
    deleteQuestionAction:(question:IQuestion)=>AnyAction

}
interface IState {

}
class Questions extends Component<IProp&RouteComponentProps&IState> {
    constructor(props:any) {
        super(props);

    }
    componentDidMount(): void {
        this.checkAdmin();
        this.props.fetchQuestionAction();
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    setAnswer(q:IQuestion){
        //alert(JSON.stringify(q));
        this.props.setQuestionAnswerAction(q);
    }
    deleteQuestion(q:IQuestion){
        this.props.deleteQuestionAction(q);
    }
    render() {
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row style={{justifyContent:'center',alignItems:'center'}} hidden={this.props.loading==false}>
                                <ActivityIndicator
                                    number={3}
                                    diameter={40}
                                    borderWidth={1}
                                    duration={300}
                                    activeColor="#9E2B9C"
                                    borderColor="white"
                                    borderRadius="50%"
                                />
                            </Row>
                            <Row style={{justifyContent:'center',alignItems:'center'}}>
                                {
                                    this.props.questions.map(q=>{
                                        return(
                                            <Col lg={6} style={{backgroundColor:'#239B55',marginLeft:10,marginRight:10,paddingBottom:15,paddingTop:15,borderRadius:5,marginBottom:15}}>
                                                <p style={{fontSize:18,color:'#fff'}}>{q.user&&(q.user.firstName+" "+q.user.lastName)}</p>
                                                <p style={{fontSize:18,color:'#fff'}}>{q.createdAt}</p>
                                                <p style={{fontSize:17,color:'#fff'}}>{q.question}</p>
                                                <AppInput placeholder={'תשובה'} value={q.answer} onChange={(event:any)=>{q.answer=event.target.value}}/>
                                                <Row style={{marginTop:10,alignItems:'center',justifyContent:'center'}}>
                                                    <AppButton text={'שלח'}  onClick={()=>this.setAnswer(q)} color={'#0073BF'} width={100}/>
                                                </Row>
                                                <Row style={{marginTop:10,alignItems:'center',justifyContent:'center'}}>
                                                    <AppButton text={'מחק'}  onClick={()=>this.deleteQuestion(q)} color={'#bf202e'} width={100}/>
                                                </Row>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Questions));
