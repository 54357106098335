import React, { Component } from 'react';
import {Table} from "antd";
import {Col, Row} from "react-flexbox-grid";
import AppButton from "../appButton";
import AppInput from "../appInput";
import IUser from "../../models/user/IUser";
import {ColumnProps} from "antd/es/table";
import {IAppState} from "../../models/app/AppState";
import {
    addUserAction,
    deleteUserAction, deleteUsersAction, disActiveUserAction,
    fetchInsertedUserAction,
    saveInsertedUserAction
} from "../../models/user/UserActions";
import {AnyAction} from "redux";
import {connect} from "dva";
import {RouteComponentProps, withRouter} from "react-router";
import IFlashCard from "../../models/flashcard/IFlashCard";
import {deleteFlashCardAction, disActiveFlashCardAction, fetchFlashCardSentences} from "../../models/flashcard/FlashCardActions";
interface IProps {
    data?:any
    withActions?:boolean
    loading:boolean
}
function mapStateToProps(state:IAppState) {
    return {
        insertedUser:state.user.insertedUser,
        loading:state.user.fetching
    };
}

const mapDispatchToProps = {
    deleteFlashCardAction:deleteFlashCardAction,
    disActiveFlashCardAction:disActiveFlashCardAction,
    fetchFlashCardSentences:fetchFlashCardSentences
};
interface IProps{
    deleteFlashCardAction:(flashCard:IFlashCard)=>AnyAction
    disActiveFlashCardAction:(flashCard:IFlashCard)=>AnyAction
    fetchFlashCardSentences:(flashCard:IFlashCard)=>AnyAction
    selectionCallBack:Function
}
interface IState {
    selected:[]
    q:string
}
class FlashCardsTable extends Component<IProps & RouteComponentProps>{
    state:IState={
        selected:[],
        q:''
    }
    getRowText(text:string){
        return <p style={{float:'right',margin:0,padding:0,fontSize:16}}>{text}</p>;
    }
    getStatusRowText(status:boolean){
        if(status==true)
            return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'green'}}>{"פעיל"}</p>;
        return <p style={{float:'right',margin:0,padding:0,fontSize:16,color:'red'}}>{"לא פעיל"}</p>;
    }
    deleteFlashCard(flashCard:IFlashCard){
        this.props.deleteFlashCardAction(flashCard)
    }
    disActiveFlashCard(flashCard:IFlashCard){
        this.props.disActiveFlashCardAction(flashCard)
    }
    async editFlashCard(flashCard:IFlashCard){
        await this.props.fetchFlashCardSentences(flashCard);
        this.props.history.push({pathname:'/flashcard/addflashcard',state:{flashCard:flashCard}});
    }
    getActionsButtons(row:IFlashCard){
        if(this.props.withActions!=undefined && this.props.withActions==false)
            return <></>
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deleteFlashCard(row)}/>
                    </Col>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'ערוך'} color={'#16B5F1'} onClick={()=>this.editFlashCard(row)}/>
                    </Col>
                    <Col style={{marginTop:5}}>
                        <AppButton width={80} text={row.status?'השבת':'הפעל'} onClick={()=>this.disActiveFlashCard(row)}/>
                    </Col>
                </Row>
            </div>
        )
    }
    onRowSelect(selectedRowKeys:any){
        this.setState({selected:selectedRowKeys})
        if(this.props.selectionCallBack)
            this.props.selectionCallBack(selectedRowKeys);
    }
    deleteFlashCards(){
        //this.props.deleteUsers(this.state.selected)
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const columns: ColumnProps<IFlashCard>[] = [
            {
                title: 'כותרת',
                dataIndex: 'title',
                key: 'title',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                defaultSortOrder: 'descend',
                //sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },

            {
                title: 'רמה',
                dataIndex: 'level',
                key: 'level',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                sorter: (a:any, b:any) => a.level-b.level,
                filters: [
                    {
                        text: '1',
                        value: '1',
                    },
                    {
                        text: '2',
                        value: '2',
                    },
                ],
                filterMultiple: false,
                onFilter: (value:any, record:any) => record.level== value,
            },
            {
                title: 'מצב',
                dataIndex: 'status',
                key: 'status',
                align:'right' as 'right',
                filters: [
                    {
                        text: 'פעיל',
                        value: '1',
                    },
                    {
                        text: 'לא פעיל',
                        value: '2',
                    },
                ],
                render: (text:any, row:any, index:any) => {
                    return this.getStatusRowText(text);
                },
                filterMultiple: false,
                onFilter: (value:any, record:any) => {
                    let status=false;
                    if(value=="1")
                        status=true
                    return record.status == status
                },
            },
            {
                title: 'תאריך הוספה',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align:'right' as 'right',
                render: (text:any, row:any, index:any) => {
                    return this.getRowText(text);
                },
                defaultSortOrder: 'descend',
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];
        const data:IFlashCard[]=this.props.data;
        data.forEach(u=>u.key=u.id)
        return (
            <div>
                <Row>
                    <Col lg={4}>
                        <AppInput placeholder={'חיפוש'} value={this.state.q} onChange={(event:any)=>this.setState({q:event.target.value})}/>
                    </Col>
                </Row>
                <Table<IFlashCard> loading={this.props.loading}  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl',marginTop:10}}  rowSelection={{selectedRowKeys:this.state.selected,onChange:(selectedRowKeys)=>this.onRowSelect(selectedRowKeys),
                    type: 'checkbox'}} dataSource={this.state.q==''?this.props.data:this.props.data.filter((d:IFlashCard)=>d.title.includes(this.state.q)  )} columns={columns} />
                <Row hidden={this.props.withActions==false}>
                    <AppButton text={'מחק מסומנים'} color={'red'} onClick={()=>this.deleteFlashCards()}/>
                </Row>

            </div>
        )
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FlashCardsTable))
