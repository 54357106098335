import React, { Component } from 'react';
import { connect } from 'dva';
import Header from "../../components/header";
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'antd/dist/antd.css';
import IUser from "../../models/user/IUser";
import {IAppState} from "../../models/app/AppState";
import {RouteComponentProps, withRouter} from "react-router";
import {
    deleteRegisteredSessionAction,
    fetchUserSessionsAction,
    registerToSessionAction
} from "../../models/user/UserActions";
import {AnyAction} from "redux";
import IUserRegisteredSession from "../../models/UserRegisteredSession/IUserRegisteredSession";
import {Table} from "antd";
import IGroup from "../../models/group/IGroup";
import AppButton from "../../components/appButton";
import {fetchGroupsAction} from "../../models/group/GroupActions";
import AppSelect from "../../components/appSelect";
import IKeyValue from "../../models/keyvalue/IKeyValue";
import AppInput from "../../components/appInput";
import moment from "moment";

function mapStateToProps(state:IAppState) {
    return {

        currentUserSessions:state.user.currentUserSessions,
        groups:state.group.groups
    };
}

const mapDispatchToProps = {
    fetchUserSessionsAction:fetchUserSessionsAction,
    fetchGroupsAction:fetchGroupsAction,
    registerToSessionAction:registerToSessionAction,
    deleteRegisteredSessionAction:deleteRegisteredSessionAction
};
interface IProp{
    groups:IGroup[]
    fetchGroupsAction:()=>AnyAction
    currentUserSessions:IUserRegisteredSession[];
    fetchUserSessionsAction:(id:number)=>AnyAction
    registerToSessionAction:(user:IUser,session:IUserRegisteredSession,callBack:Function)=>AnyAction
    deleteRegisteredSessionAction:(session:IUserRegisteredSession)=>AnyAction

}

interface IState {
    newSession:IUserRegisteredSession;
    showAddNewSession:boolean;
    user?:IUser;
    error?:boolean;
    success?:boolean;
}
class UserSessions extends Component<IProp & RouteComponentProps> {
    constructor(props:any) {
        super(props);

    }
    state:IState={
        newSession:{date:new Date(),numOfSession:0},
        showAddNewSession:false
    }
    componentWillMount() {
        this.checkAdmin();
        this.props.fetchGroupsAction();
        let localState:any=this.props.location.state;
        if(localState!=null && localState!=undefined && localState.hasOwnProperty("user")){
            let user=localState.user;
            this.props.fetchUserSessionsAction(user.id);
            this.setState({user:user,editMode:true})
            return;
        }

       // this.props.fetchInsertUser(this.handleInsertedUser.bind(this));
    }
    async checkAdmin(){
        let token= await localStorage.getItem('token');
        if(token==null || token==''){
            this.props.history.push('/');
        }
    }
    getRowText(text:string){

        return <p style={{float:'right',margin:0,padding:0,fontSize:16,whiteSpace:'pre-line'}}>{text}</p>;
    }
    deleteRegisteredSession(session:IUserRegisteredSession){
        this.props.deleteRegisteredSessionAction(session);
    }
    getActionsButtons(row:IUserRegisteredSession){
        return(
            <div style={{direction:'rtl'}}>
                <Row>
                    <Col style={{marginTop:5,marginLeft:5}}>
                        <AppButton width={80} text={'מחק'} color={'red'} onClick={()=>this.deleteRegisteredSession(row)}/>
                    </Col>
                </Row>
            </div>
        )
    }
    moveToAddSession(){
        this.setState({showAddNewSession:!this.state.showAddNewSession})
    }
    setGroup(id:number){
        let s=this.state.newSession;
        let group=this.props.groups.filter(g=>g.id==id)[0];
        s.groupId=group.id;
        s.group=group;
        this.setState({newSession:s});

    }
    setDate(date:Date){
        let s=this.state.newSession;
        s.date=date;
        this.setState({newSession:s,error:null});
        if(this.state.newSession.group!=null && this.state.newSession.group!=undefined){
            let mom=moment(date);
            let group=this.state.newSession.group;
            let days=group.GroupSessionsDays.filter(g=>g.day==(mom.day()+1))
            if(days==null || days.length==0)
                this.setState({error:'קבוצה זו לא לומדת ביום שבחרת'})
        }


    }
    register(){

        this.state.user&&this.props.registerToSessionAction(this.state.user,this.state.newSession,this.handleRegister.bind(this));
    }
    handleRegister(success:boolean,msg:string){
        if(success){
            this.setState({success:msg})
        }else{
            this.setState({error:msg})
        }
    }
    render() {
        const columns = [
            {
                title: 'תאריך מפגש',
                dataIndex: 'date',
                key: 'date',
                align:'right' as 'right',
                render: (text:string, row:any, index:any) => {
                    return this.getRowText(text.substring(0,10));
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: 'קבוצה',
                dataIndex: 'group.name',
                key: 'group.name',
                align:'right' as 'right',
                render: (text:string, row:any, index:any) => {
                    return this.getRowText(text.substring(0,10));
                },
                sorter: (a:any, b:any) => a.title.localeCompare( b.title)
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (text:any, row:any, index:any) => {
                    return this.getActionsButtons(row);
                }
            }
        ];
        const options:IKeyValue[]=[];
        if(this.props.groups){
            this.props.groups.forEach((g:IGroup)=>options.push({key:g.id,value:g.name}))
        }
        return (
            <div style={{direction:'rtl'}}>
                <Grid fluid style={{padding:0}}>
                    <Header/>
                    <Col last='lg'  xs={12} sm={9} md={9} lg={10} style={{backgroundColor:'white',width:'100%',float:'left'}}>
                        <div style={{height:'fit-content',paddingLeft:20,paddingRight:20,paddingTop:195}}>
                            <Row>
                                <h3>{this.state.user&&(this.state.user.firstName+" "+this.state.user.lastName)}</h3>
                            </Row>
                            <Row>
                                <h3>{this.state.user&&(this.state.user.personId)}</h3>

                            </Row>

                            <Row>
                                <AppButton text={'הוסף מפגש חדש'} onClick={()=>this.moveToAddSession()}/>
                            </Row>
                            <Row hidden={ !(this.state.user&&(this.state.user.maxSessions<=this.props.currentUserSessions.length))} style={{marginTop:15,marginRight:5}}>
                                <p style={{color:'red'}}>*משתמש זה רשום לכל המפגשים שרכש אותם</p>
                            </Row>
                            <Row hidden={this.state.showAddNewSession==false}>
                                <Col lg={4}>
                                    <AppSelect options={options}  value={this.state.newSession.group&&this.state.newSession.group.id} onChange={(event:any)=>this.setGroup(event.target.value)}/>
                                </Col>
                                <Col lg={4}>
                                    <AppInput placeholder={'תאריך התחלה'}   value={this.state.newSession.date} type={'date'} onChange={(event:any)=>this.setDate(event.target.value)}/>
                                </Col>

                            </Row>
                            <Row hidden={this.state.showAddNewSession==false} style={{marginTop:15,marginRight:5}}>
                                <AppButton   width={100} text={'הוסף'} onClick={()=>this.register()}/>
                            </Row>
                            <Row hidden={!this.state.error}>
                                <p style={{fontSize:22,marginRight:10,color:'red'}}>{this.state.error}</p>
                            </Row>
                            <Row hidden={!this.state.success}>
                                <p style={{fontSize:22,marginRight:10,color:'green'}}>{this.state.success}</p>
                            </Row>
                            <div style={{marginTop:30}}>
                                <Table  bodyStyle={{direction:'rtl'}}  style={{direction:'rtl'}}
                                   dataSource={this.props.currentUserSessions} columns={columns} />
                            </div>
                        </div>

                    </Col>
                </Grid>
            </div>
        );
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserSessions));
