import {Dispatch} from "redux";

export function fetchPushNotificationsAction() {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'PushNotifications/fetch',
            payload:{
            }
        });
    };

}
export function fetchPushNotificationsActionUsers(pushId:number,callBack:Function) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: 'PushNotifications/fetchNotificationUsers',
            payload:{
                pushId:pushId,
                callBack:callBack
            }
        });
    };

}
