import IUser from "../user/IUser";
import {Dispatch} from "redux";
import IFlashCard from "./IFlashCard";
import ISentence from "../sentences/ISentence";
export function fetchFlashCardsAction() {
    //alert("hello")
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/fetch',
        });
    };

}
export function addFlashCardAction(flashCard:IFlashCard,sentences:ISentence[],callBack:Function) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/addFlashCard',
            payload:{
                flashCard:flashCard,
                sentences:sentences,
                callBack:callBack
            }
        });
    };

}
export function deleteFlashCardAction(flashCard:IFlashCard) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/deleteFlashCard',
            payload:{
                flashCard:flashCard,
            }
        });
    };

}
export function disActiveFlashCardAction(flashCard:IFlashCard) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/disActiveFlashCard',
            payload:{
                flashCard:flashCard,
            }
        });
    };
}
export function fetchFlashCardSentences(flashCard:IFlashCard) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/fetchFlashCartSentences',
            payload:{
                flashCard:flashCard,
            }
        });
    };
}
export function updateFlashCardAction(flashCard:IFlashCard,sentences:ISentence[],callBack:Function) {
    return (dispatch: Dispatch) => {

        return dispatch({
            type: 'flashcard/updateFlashCard',
            payload:{
                flashCard:flashCard,
                sentences:sentences,
                callBack:callBack
            }
        });
    };

}
