
import AdminState from "./AdminState";
import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import AdminServices from "./AdminServices";
import IAdmin from "./IAdmin";


interface IAppModel{
    namespace:string
    state:AdminState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const AdminModal:IAppModel={
    namespace: 'admin',
    state: {
       currentAdmin:undefined,
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *login({payload}:any,{call,put}:any){
           // yield put({type: 'updateState', payload: {fetching: true}});
            try{
                let res=yield call(AdminServices.login,payload.userName,payload.password);
                let token:string=res.data.token;
                localStorage.setItem('token',token);
                yield put({type: 'updateState', payload: {currentAdmin: token}});
                payload.callBack(true);
            }catch (e) {
                payload.callBack(false);
            }

        },

    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default AdminModal

