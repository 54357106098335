import {IAppState} from "./AppState";


interface IAppModel{
    namespace:string
    state:IAppState
    reducers:any
    effects:any
    subscriptions:any
}
const AppModel:IAppModel={
    namespace: 'app',
    state: {
        user:{users:[],fetching:false,currentUserSessions:[]},
        sentences:{sentences:[{index:0,en:'',he:'',enVoice:null,heVoice:null}]},
        flashcard:{flashCards:[],loading:false},
        group:{groups:[],loading:false,currentGroupSessions:[]},
        session:{sessions:[],loading:false},
        question:{questions:[],loading:false},
        admin:{currentAdmin:undefined},
        PushNotifications:{notifications:[]}

    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *saveHistory({payload}:any,{call,put}:any){
            alert("here")
        },
    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default AppModel
