import React, { Component } from 'react';
import {Button, Modal} from "react-bootstrap";
import UsersTable from "../usersTable";
import AppInput from "../appInput";
import IUser from "../../models/user/IUser";
import {connect} from "dva";
import {withRouter} from "react-router";
import {IAppState} from "../../models/app/AppState";
import {fetchGroupsAction, fetchGroupUsersAction} from "../../models/group/GroupActions";
import {fetchUsersAction} from "../../models/user/UserActions";
import {AnyAction} from "redux";



function mapStateToProps(state:IAppState) {
    return {
        users:state.user.users,
        fetching:state.user.fetching
    };
}

const mapDispatchToProps = {
    fetchUsers:fetchUsersAction
};
interface IProp {
    show:boolean
    closeCallBack:any;
    data:IUser[],
    currentSelectedUser:IUser[]
    fetchUsers:() => AnyAction
    fetching:boolean
    users:IUser[];
    selectionCallBack:Function;
}
interface IState {
    selectedUser:IUser[]
}
class SelectUserModal extends Component<IProp & IState>{
    state:IState={
        selectedUser:[]
    }
    componentDidMount(): void {
        this.props.fetchUsers();
    }
    selectionCallBack(usersIds:[]){
        let selected:IUser[]=[];
        usersIds.forEach((id:number)=>{
            this.props.users.forEach((u:IUser)=>{
                if(u.id==id)
                    selected.push(u)
            })
        })
        //alert(JSON.stringify(selected))
        this.setState({selectedUser:selected})
    }
    save(){
        this.props.selectionCallBack(this.state.selectedUser)
        this.setState({selectedUser:[]})
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let users:IUser[]=[]
        for(let i=0;i<this.props.users.length;i++){
            let founded=false;
            for(let j=0;j<this.props.currentSelectedUser.length;j++)
            {
                if(this.props.currentSelectedUser[j].id==this.props.users[i].id)
                    founded=true;
            }
            if(!founded)
                users.push(this.props.users[i])
        }
        return (
            <Modal show={this.props.show} size="xl" style={{display:'flex',flexDirection:'row-reverse'}}>
                <Modal.Header style={{direction:'rtl'}}>
                    <Modal.Title>בחר משתמשים</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UsersTable selectionCallBack={this.selectionCallBack.bind(this)} data={users} loading={this.props.fetching} withActions={false}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{marginLeft:10}} onClick={()=>this.props.closeCallBack()}>
                        סגור
                    </Button>
                    <Button variant="primary" onClick={()=>this.save()} >
                        שמור
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SelectUserModal);
