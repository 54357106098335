



import UserServices from "../user/UserServices";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import SessionServices from "../session/SessionServices";
import PushNotificationState from "./PushNotificationState";
import PushNotificationServices from "./PushNotificationServices";




interface IAppModel{
    namespace:string
    state:PushNotificationState;
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const PushNotificationsModel:IAppModel={
    namespace: 'PushNotifications',
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    state: {
        notifications:[]
    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){

            try{
                let res=yield call(PushNotificationServices.fetch);
                yield put({type: 'updateState', payload: {notifications:res.data }});
            }catch (e) {

            }

        },
        *fetchNotificationUsers({payload}:any,{call,put,select}:any){

            try{
                let res=yield call(PushNotificationServices.fetchUser,payload.pushId);
                payload.callBack(res.data)
            }catch (e) {
                payload.callBack([])
            }

        },

    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default PushNotificationsModel
