
import SentencesState from "../sentences/SentencesState";
import IUser from "../user/IUser";
import {IAppState} from "../app/AppState";
import ISentence from "../sentences/ISentence";
import FlashCardState from "./FlashCardState";
import UserServices from "../user/UserServices";
import FlashCardServices from "./FlashCardServices";
import IFlashCard from "./IFlashCard";


interface IAppModel{
    namespace:string
    state:FlashCardState
    reducers:any
    effects:any
    subscriptions:any
}
//const users = ;
const FlashCardModel:IAppModel={
    namespace: 'flashcard',
    state: {
        flashCards:[],
        loading:false
    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *fetch({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(FlashCardServices.fetchFlashCards);
                yield put({
                    type: 'updateState',
                    payload: {
                        flashCards: res.data
                    },
                });
            }catch (e) {

            }finally {


                yield put({type: 'updateState', payload: {loading: false}});
            }
        },

        *addFlashCard({payload}:any,{call,put,select}:any){

            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(FlashCardServices.addFlashCard,payload.flashCard,payload.sentences);
                yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
                payload.callBack(true,"פעולה בוצעה בהצלחה")
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {loading: false}});

            }
        },
        *deleteFlashCard({payload}:any,{call,put,select}:any){
            yield call(FlashCardServices.deleteFlashCard,payload.flashCard);
            let flashCards:IFlashCard[]=yield select((state:IAppState) => state.flashcard.flashCards);
            flashCards=flashCards.filter(f=>f.id!=payload.flashCard.id)
            yield put({type: 'updateState', payload: {flashCards: flashCards}});
        },
        *disActiveFlashCard({payload}:any,{call,put,select}:any){
            yield call(FlashCardServices.disActiveFlashCard,payload.flashCard);
            let flashCards:IFlashCard[]=yield select((state:IAppState) => state.flashcard.flashCards);
            payload.flashCard.status=!payload.flashCard.status;
            flashCards=flashCards.filter(f=>f.id!=payload.flashCard.id)
            flashCards.push(payload.flashCard)
            yield put({type: 'updateState', payload: {flashCards: flashCards}});
        },
        *fetchFlashCartSentences({payload}:any,{call,put,select}:any){
            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(FlashCardServices.fetchFlashCartSentences,payload.flashCard.id);
                let flashCards:IFlashCard[]=yield select((state:IAppState) => state.flashcard.flashCards);
                let flashCard=payload.flashCard;
                flashCard.sentences=res.data;
                flashCards.push(flashCard)
                yield put({type: 'updateState', payload: {flashCards: flashCards}});
            }catch (e) {

            }finally {


                yield put({type: 'updateState', payload: {loading: false}});
            }
        },
        *updateFlashCard({payload}:any,{call,put,select}:any){

            yield put({type: 'updateState', payload: {loading: true}});
            try{
                let res=yield call(FlashCardServices.updateFlashCard,payload.flashCard,payload.sentences);
                yield put({type: 'updateState', payload: {success: "פעולה בוצעה בהצלחה"}});
                payload.callBack(true,"פעולה בוצעה בהצלחה")
            }catch (e) {
                payload.callBack(false,e.data);
            }finally {
                yield put({type: 'updateState', payload: {loading: false}});

            }
        },

    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default FlashCardModel

